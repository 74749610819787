@import "plugins/bootstrap/bootstrap";
@import "../js/plugins/select2/select2.scss";
//@import "../js/plugins/lightbox/lightbox.scss";

$white:#ffffff;
$theme_color1:#00337a;
$theme_color2:#eaf2f7;
$theme_color3:$white;
$theme_color4:#001b42;

$accent_color1:#ffae00;
$accent_color2:#0071c1;
$accent_color3:#089d2f;

$font_color1:#000000;
$font_color2:#737373;
$font_color3:#9aa0a9;
$main_font:'Nunito Sans', sans-serif;

$border_color:#d6d6d6;

@mixin anim{
    transition: 0.3s all ease;
}

@mixin fill{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@mixin bg_pos{
    background-repeat: no-repeat;
    background-position: center;
}

@mixin vertical{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
}

.default_font{
    font-size: 14px;
    line-height: 30px;
    @media only screen and (max-width: 1500px) {
        font-size: 13px;
        line-height: 29px;
    }
    h1{
        font-size: 30px;
        line-height: 40px;
    }
    h2{
        font-size: 26px;
        line-height: 36px;
        @media only screen and (max-width: 1500px) {
            font-size: 24px;
            line-height: 34px;
        }
    }
    h3{
        font-size: 24px;
        line-height: 34px;
        @media only screen and (max-width: 1500px) {
            font-size: 21px;
            line-height: 31px;
        }
    }
    h4{
        font-size: 21px;
        line-height: 31px;
        @media only screen and (max-width: 1500px) {
            font-size: 18px;
            line-height: 28px;
        }
    }
    h5{
        font-size: 18px;
        line-height: 28px;
        @media only screen and (max-width: 1500px) {
            font-size: 15px;
            line-height: 25px;
        }
    }
    h6{
        font-size: 15px;
        line-height: 25px;
        @media only screen and (max-width: 1500px) {
            font-size: 14px;
            line-height: 24px;
        }
    }
    ul{
        padding-left: 18px;
        list-style: disc;
    }
    ol{
        padding-left: 18px;
    }
    a{
        text-decoration: underline;
    }
    h1,h2,h3,h4,h5,h6,ul,ol,a,p,img{
        margin-bottom: 15px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 15px;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
    h1,h2,h3,h4,h5,h6{
        //font-weight: 700;
        color: $font_color1;
    }
}

body{
    font-family: $main_font;
    h1,h2,h3,h4,h5,h6,ul,ol,a,p{
        font-weight: 400;
        margin: 0;
    }
    ul{
        list-style: none;
        padding: 0;
    }
    font-size: 18px;
    @media only screen and (max-width: 1500px) {
        font-size: 15px;
    }
    img{
        max-width: 100%;
        height: auto;
    }
}

.page{
    overflow: hidden;
}

.btn1{
    display: inline-block;
    font-size: 11px;
    text-transform: uppercase;
    padding: 0 10px;
    border: 1px solid $white;
    line-height: 36px;
    color: $white;
    font-weight: 800;
    @include anim;
    cursor: pointer;
    border-radius: 5px;
    &:hover{
        text-decoration: none;
        border: 1px solid $accent_color1;
        background: $accent_color1;
        color: $white;
    }
    &.editt, &.del{
        line-height: 41px;
        display: inline-block;
    }
    &.edit{
        border: 1px solid $theme_color1;
        color: $theme_color1;
        &:hover{
            color: $white;
            background: $theme_color1;
        }
    }
    &.del{
        color:$font_color1;
        border: 1px solid #828282;
        &:hover{
            color: $white;
            background: #828282;
        }
    }
    &.add{
        background: $accent_color2;
        span{
            padding-left: 23px;
            background-repeat: no-repeat;
            background-position: left center;
            background-image: url("./../img/plus_icon2.png");
        }
    }
}
.btn1active{
    color: $white !important;
    background: $theme_color1 !important;
}

.btn2{
    display: inline-block;
    font-size: 11px;
    text-transform: uppercase;
    padding: 0 20px;
    line-height: 38px;
    background: $white;
    font-weight: 800;
    color:#003580;
    @include anim;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    &:after{
        content: '';
        @include fill;
        border: 3px solid transparent;
        @include anim;
        border-radius: 5px;
    }
    &:hover{
        text-decoration: none;
        &:after{
            border: 3px solid $accent_color1;
        }
    }
}


.ask_btn_developer{
    cursor:pointer;
    display: inline-block;
    font-size: 11px;
    line-height: 40px;
    border: 1px solid $accent_color2;
    color:$accent_color2;
    font-weight: 800;
    padding-right: 36px;
    padding-left: 16px;
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-image: url("./../img/ask_arr.png");
    @include anim;
    padding-top: 3px;
    @media only screen and (max-width: 991px) {
        padding-right: 16px;
        background-image: none;
    }
    &:hover{
        background-color: $accent_color2;
        color: $white;
        padding-right: 16px;
        text-decoration: none;
    }
}

.ad_btn{
    @media only screen and (max-width: 991px) {
        width: 100%;
    }
}

.ask_btn, .ad_btn, .ask_btn_advanced{
    cursor:pointer;
    display: inline-block;
    font-size: 11px;
    line-height: 40px;
    border: 1px solid $accent_color2;
    color:$accent_color2;
    font-weight: 800;
    padding-right: 36px;
    padding-left: 16px;
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-image: url("./../img/ask_arr.png");
    @include anim;
    padding-top: 3px;
    border-radius: 5px;
    text-transform: uppercase;
    &:hover{
        background-color: $accent_color2;
        color: $white;
        padding-right: 16px;
        text-decoration: none;
    }
}

.ask_btn2{
    cursor:pointer;
    display: inline-block;
    color: $white;
    background: $accent_color2;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 800;
    padding: 0 10px;
    line-height: 30px;
    border-radius: 5px;
}



.rwd_hidden{
    display: block;
    @media only screen and (max-width: 991px) {
        display: none;
    }
}

.page_header{
    position: relative;
    z-index: 10;

    background: rgb(1,30,70);
    background: -moz-linear-gradient(180deg, rgba(1,30,70,1) 0%, rgba(2,61,148,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(1,30,70,1) 0%, rgba(2,61,148,1) 100%);
    background: linear-gradient(180deg, rgba(1,30,70,1) 0%, rgba(2,61,148,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#011e46",endColorstr="#023d94",GradientType=1);

    @media only screen and (max-width: 991px) {
        .btn1{
            color: $theme_color1;
            background: $white;
        }
        .btn1, .btn2{
            display: block !important;
            margin-bottom: 10px;
        }
    }
    .header_top{
        padding: 15px 0;
        /*@media only screen and (max-width: 991px) {
            padding: 20px 0 0 0;
        }*/
        @media only screen and (max-width: 991px) {
            padding: 15px 0 0 0;
        }
        .logo_box{
            position: relative;
            padding-top: 8px;
            @media only screen and (max-width: 991px) {
                padding-top: 0;
                img{
                    width: 230px;
                    height: auto;
                }
                div.rwd_btn{
                    position: absolute;
                    right: 0px;
                    top: 3px;
                    width: 30px;
                    z-index: 5;
                    > div{
                        width: 100%;
                        height: 3px;
                        margin-bottom: 3px;
                        background: $accent_color1;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        .user_navigation{
            text-align: right;
            @media only screen and (max-width: 991px) {
                padding-top: 15px;
            }
            > ul{
                > li{
                    display: inline-block;
                    vertical-align: top;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        &.rwd_hidden{
                            display: none;
                        }
                    }
                    .langs{
                        @media only screen and (max-width: 991px) {
                           position: relative;
                            top: -23px;
                        }
                        .dropdown{
                            &.open{
                                .dropdown-menu{
                                    top: 100%;
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                            .btn{
                                padding: 0;
                                position: relative;
                                z-index: 1111;
                                background: transparent;
                                border: none;
                                span{
                                    display: none;
                                }
                            }
                            .dropdown-menu{
                                top: 0%;
                                min-width: 0;
                                width: 100%;
                                text-align: center;
                                border: none;
                                display: block;
                                visibility: hidden;
                                opacity: 0;
                                @include anim;
                                padding: 0;
                                margin: 0;
                                background: transparent;
                                @media only screen and (max-width: 991px) {
                                    box-shadow: none;
                                    text-align: right;
                                }
                                > li{
                                    width: 100%;
                                    > a{
                                        padding: 0;
                                        font-size: 11.5px;
                                        @include anim;
                                        position: relative;
                                        &:hover{
                                            background: $accent_color1;
                                            color: $white;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .classifieds{
                        margin: 0 40px;
                        @media only screen and (max-width: 1500px) {
                            margin: 0 20px;
                        }
                        @media only screen and (max-width: 991px) {
                            margin: 0;
                            text-align: center;
                        }
                    }
                    .classifieds, .login_register{
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            text-align: center;
                        }
                        ul{
                            li{
                                display: inline-block;
                                vertical-align: top;
                                margin-right: 20px;
                                @media only screen and (max-width: 991px) {
                                    margin-right: 0;
                                    width: 100%;
                                    margin-bottom: 0px;
                                    a{
                                        width: 100%;
                                    }
                                }
                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .header_bottom{
        padding-top: 15px;
        margin-bottom: 2px;
        padding-bottom: 2px;
        @media only screen and (max-width: 991px) {
            padding-top: 0;
        }
        .menu_box{
            > ul{
                @media only screen and (max-width: 991px) {
                    display: flex;
                    flex-flow: column;
                }
                > li{
                    display: inline-block;
                    vertical-align: middle;
                    @media only screen and (max-width: 991px) {
                        display: block;

                    }
                    &:first-child{
                        width: calc(100% - 169px);
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            order: 2;
                        }
                    }
                    &:last-child{
                        width: 169px;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            order: 1;
                            .btn1{
                                width: 100%;
                                text-align: center;
                            }
                        }
                    }
                    .classifieds{
                        margin-top: -22px;
                        .btn1{
                            background: $accent_color1;
                            border:1px solid $accent_color1;
                        }
                        @media only screen and (max-width: 991px) {
                            margin-top: 0;
                        }
                    }
                }
            }
            nav.menu{
               > ul{
                   > li{
                        display: inline-block;
                        vertical-align: top;
                        margin-right: 20px;
                       position: relative;
                       &:hover{
                           > ul{
                               opacity: 1;
                               visibility: visible;
                           }
                       }
                       > ul{
                           position: absolute;
                           top: calc(100% + 4px);
                           left: 0;
                           display: block !important;
                           background:rgba(250, 251, 252, 1);
                           @include anim;
                           opacity: 0;
                           visibility: hidden;
                           height: 250px;
                           overflow-y: scroll;
                           @media only screen and (max-width: 991px) {
                               display: none !important;
                           }
                           &::-webkit-scrollbar {
                               width: 10px;
                           }

                           /* Track */
                           &::-webkit-scrollbar-track {
                               background: #f1f1f1;
                           }

                           /* Handle */
                           &::-webkit-scrollbar-thumb {
                               background: #d6d6d6;
                           }

                           /* Handle on hover */
                           &::-webkit-scrollbar-thumb:hover {
                               background: #555;
                           }
                           > li{
                               border-bottom: 1px solid #d6d6d6;
                               white-space: nowrap;
                               width: auto;
                               a{
                                   font-size: 15px;
                                   color: $font_color1;
                                    padding: 10px 20px;
                                   background-repeat: no-repeat;
                                   background-position: left center;
                                   display: block;
                                   @media only screen and (max-width: 1200px) {
                                       font-size: 11px;
                                       line-height: 21px;
                                   }
                               }
                           }
                       }
                        @media only screen and (max-width: 1200px) {
                            margin-right: 15px;
                        }
                        @media only screen and (max-width: 991px) {
                            margin-right: 0;
                            width: 50%;
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                        div{
                            //padding: 0 20px 25px 20px;
                            padding: 0 0px 25px 0px;
                            position: relative;
                            @media only screen and (max-width: 991px) {
                                padding: 0 0 10px 0;
                                margin-bottom: 10px;
                            }
                            &:after{
                                content: '';
                                border-bottom: 3px solid $white;
                                width: 0;
                                left: 50%;
                                @include anim;
                                position: absolute;
                                top: 100%;
                            }
                            a{
                                font-size: 15px;
                                color: $white;
                                padding-left: 40px;
                                background-repeat: no-repeat;
                                background-position: left center;
                                display: block;
                                @media only screen and (max-width: 1200px) {
                                    font-size: 11px;
                                    line-height: 21px;
                                }
                            }
                            &.on{
                                a{
                                    text-decoration: none;
                                }
                                &:after{
                                    left: 0;
                                    width: 100%;
                                }
                            }
                        }
                        &:hover{
                            div{
                                a{
                                    text-decoration: none;
                                }
                                &:after{
                                    left: 0;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.section_header{
    color: $font_color2;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
    @media only screen and (max-width: 1500px) {
        font-size: 15px;
        line-height: 25px;
    }
}

.search{
    @include vertical;
    //height: 310px;
    height: 250px;
    //background: $theme_color2;

    background: rgb(237,91,0);
    background: -moz-linear-gradient(68deg, rgba(237,91,0,1) 0%, rgba(255,174,0,1) 100%);
    background: -webkit-linear-gradient(68deg, rgba(237,91,0,1) 0%, rgba(255,174,0,1) 100%);
    background: linear-gradient(68deg, rgba(237,91,0,1) 0%, rgba(255,174,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ed5b00",endColorstr="#ffae00",GradientType=1);

    /*@media only screen and (max-width: 1500px) {
        height: 260px;
    }*/

    .section_header {
        h1, p {
            color: #fff !important;
        }
    }

    @media only screen and (max-width: 991px) {
        height: auto;
        padding: 30px 0;
        .section_header{
            p{
                color: #000000;
            }
            div.default_font{
                h1{
                    font-weight: 700;
                }
            }
        }
    }
    .search_box{
        //border: 3px solid $accent_color1;
        border: 3px solid #023177;
        border-radius: 5px;
        > form{
            .s_holder{
                display: inline-block;
                vertical-align: top;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                &.type_holder{
                    .chosen-container-single{
                        .chosen-single{
                            background-image: url("./../img/key_icon.png");
                        }
                    }
                    @media only screen and (max-width: 991px) {
                        border-bottom: 3px solid $accent_color1;
                    }
                }
                &.target_holder{
                    .chosen-container-single{
                        .chosen-single{
                            background-image: url("./../img/home_icon.png");
                        }
                    }

                }
                &.target_holder, &.city_holder{
                    width: calc((100% - (300px + 160px)) / 2);
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        border-bottom: 3px solid $accent_color1;
                    }
                }
                &.city_holder{
                    //border-right: 3px solid $accent_color1;
                    border-right: 3px solid #023177;
                    @media only screen and (max-width: 991px) {
                        border-right: none;
                    }
                    .chosen-container-single {
                        .chosen-single {
                            background-repeat: no-repeat;
                            background-position: left 30px center;
                            background-image: url("./../img/pin_icon.png");
                        }
                    }
                }
                &.type_holder{
                    width: 300px;
                    //border-right: 3px solid $accent_color1;
                    border-right: 3px solid #023177;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        border-right: none;
                    }
                }
                ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color: $font_color1;
                }
                ::-moz-placeholder { /* Firefox 19+ */
                    color: $font_color1;
                }
                :-ms-input-placeholder { /* IE 10+ */
                    color: $font_color1;
                }
                :-moz-placeholder { /* Firefox 18- */
                    color: $font_color1;
                }
                input{
                    outline: none;
                    &.s_input{
                        border: 3px solid $white;
                        height: 60px;
                        width: 100%;
                        font-size: 16px;
                        font-weight: 600;
                        color: $font_color1;
                        padding: 0 68px;
                        background-repeat: no-repeat;
                        background-position: left 30px center;
                        background-image: url("./../img/pin_icon.png");
                    }
                }
                .s_submit_btn{
                    height: 60px;
                    line-height: 56px;
                    width: 160px;
                    //background: $accent_color2;
                    background-color:#023177;
                    position: relative;
                    font-size: 16px;
                    letter-spacing: 1px;
                    text-transform: capitalize;
                    color: $white;
                    text-align: center;
                    //border-left: 3px solid $accent_color1;
                    border-left: 3px solid #023177;
                    @media only screen and (max-width: 991px) {
                            width: 100%;
                        border-left: none;
                    }
                    p{
                        display: inline-block;
                        padding-left: 30px;
                        background-repeat: no-repeat;
                        background-position: left center;
                        background-image: url("./../img/search_icon.png");
                        font-weight: 700;
                    }
                    .s_submit{
                        @include fill;
                        opacity: 0;
                    }
                }
                .chosen-container-single {
                    .chosen-single{
                        border: none;
                        height: 60px;
                        background: $white;
                        box-shadow: none;
                        padding: 0 20px 0 68px;
                        font-size: 13px;
                        color: #a4b1bf;
                        border-radius: 0;
                        line-height: 60px;
                        color: $font_color1;
                        font-weight: 600;
                        background-repeat: no-repeat;
                        background-position: left 30px center;

                        div{
                            width: 50px;
                            b{
                                @include bg_pos;
                                background-image: url('./../img/select_arr.png');
                            }
                        }
                    }
                    .chosen-search{
                        input{
                            //border: 1px solid $border_color;
                            color: #a4b1bf;
                        }
                    }
                    .chosen-drop{
                        //border: 1px solid $border_color;
                        border-top: none;
                        width: calc(100% + 6px);
                        left: -3px;
                        border: 3px solid $accent_color1;
                        border-top: 0;
                        top: calc(100% + 1px);
                        .highlighted{
                           // background: $accent_color;
                            color: $white;
                        }
                    }
                    .chosen-results {
                        li{
                            padding: 10px 20px;
                            color: $font_color1;
                        }
                    }
                }
            }
        }
    }
}

.home_flats{
    .flats_list{
        ul{
            margin: 0 -15px;
            li{
                padding: 0 15px;
                display: inline-block;
                vertical-align: top;
                margin-bottom: 20px;
                width: 33%;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                article{
                    height: 270px;
                    width: 100%;
                    position: relative;
                    color: $white;
                    border-radius: 5px;
                    overflow: hidden;
                    @media only screen and (max-width: 991px) {
                        height: 0;
                        padding-bottom: 100%;
                    }
                    div.image{
                        @include fill;
                        @include bg_pos;
                        background-size: cover;
                        .cover{
                            @include fill;
                            background-image: url("./../img/cover.png");
                            background-size: cover;
                        }
                    }
                    .desc{
                        @include fill;
                        padding: 30px;
                        strong{
                            font-weight: 800;
                        }
                        .default_font{
                            color: $white;
                            h1,h2,h3,h4,h5,h6{
                                color: inherit;
                            }
                        }
                    }
                    .price{
                        position: absolute;
                        right: 15px;
                        bottom: 15px;
                        background: #3f3f3f;
                        padding: 5px 17px;
                        color: $white;
                        span{
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 13px;
                            color: #cfcfcf;
                        }
                        .default_font{
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }
}

.carousel_content{
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;
    &.moree{
        .carousel_nav{
            padding-top: 16%;
        }
        ul{
            > li{
                width: 32%;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
            }
        }
    }
    .carousel_nav{
        padding-top: 8%;
        @include fill;
        @media only screen and (max-width: 991px) {
            @include vertical;
            padding-top: 0;
        }
        > div{
            width: 100%;
            img{
                margin: 0 20px;
                cursor: pointer;
                position: relative;
                z-index: 5;
            }
        }
        .text-right{
            text-align: right;
        }
    }
    ul{
        position: relative;
        white-space: nowrap;
        z-index: 4;
        > li{
            white-space: normal;
            width: 23.5%;
            margin-right: 2%;
            display: inline-block;
            vertical-align: top;
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
            a{
                position: relative;
                z-index: 4;
                &:hover{
                    text-decoration: none;
                }
            }
            article{
                div.image{
                    width: 100%;
                    height: 0;
                    padding-bottom: 86%;
                    @include bg_pos;
                    background-size: cover;
                    border-radius: 5px;
                }
               div.desc{
                   font-size: 13px;
                   color:#787878;
                   padding: 17px 20px;
                   color: $font_color2;
                   p{
                       font-weight: 600;
                   }
                   header{
                       color: $font_color1;
                       margin-bottom: 5px;
                       h3{
                           font-size: 20px;
                           line-height: 30px;
                       }
                   }
                   .availability{
                       padding-top: 15px;
                       > div{
                           display: inline-block;
                           vertical-align: top;
                           &.counts{
                               background: $accent_color2;
                               width: 24px;
                               height: 24px;
                               line-height: 24px;
                               border-radius: 50%;
                               color: $white;
                               font-size: 12px;
                               font-weight: 600;
                               text-align: center;
                               p{
                                   font-weight: inherit;
                               }
                           }
                           &.counts_desc{
                               width: calc(100% - 24px);
                               padding-left: 10px;
                               font-weight: 600;
                               color: $font_color1;
                               padding-top: 3px;
                               p{
                                   font-weight: inherit;
                               }
                           }
                       }
                   }
               }
           }
        }
    }
}

.categoires_list{
    ul{
        margin: 0 -15px;
        li{
            display: inline-block;
            vertical-align: top;
            width: 25%;
            padding: 0 15px;
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
            a{
                display: block;
                &:hover{
                    text-decoration: none;
                }
                div.image{
                    @include bg_pos;
                    background-size: cover;
                    position: relative;
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                    div.cover{
                        @include fill;
                        background: rgba(0,0,0,0.2);
                        @include vertical;
                        > div{
                            width: 100%;
                            text-align: center;
                        }
                    }
                }
                div.desc{
                    padding: 17px;
                    color: $font_color2;
                    header{
                        color: $font_color1;
                    }
                }
            }
        }
    }
}

.page_footer{
    div.newsletter{
        height: 440px;
        width: 100%;
        @include vertical;
        //background: $theme_color1;
        background: rgba(0,131,197,1);
        background: -moz-linear-gradient(top, rgba(0,131,197,1) 0%, rgba(5,48,107,1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,131,197,1)), color-stop(100%, rgba(5,48,107,1)));
        background: -webkit-linear-gradient(top, rgba(0,131,197,1) 0%, rgba(5,48,107,1) 100%);
        background: -o-linear-gradient(top, rgba(0,131,197,1) 0%, rgba(5,48,107,1) 100%);
        background: -ms-linear-gradient(top, rgba(0,131,197,1) 0%, rgba(5,48,107,1) 100%);
        background: linear-gradient(to bottom, rgba(0,131,197,1) 0%, rgba(5,48,107,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0083c5', endColorstr='#05306b', GradientType=0 );
        border-bottom: 1px solid $white;
        @media only screen and (max-width: 1500px) {
            height: 380px;
        }
        @media only screen and (max-width: 991px) {
            height: auto;
            padding: 30px 0;
        }
        div.desc{
            color: $white;
            line-height: 35px;
            text-align: center;
            .newsletter_header{
                font-size: 42px;
                line-height: 52px;
                margin-bottom: 15px;
                @media only screen and (max-width: 1500px) {
                    font-size: 36px;
                    line-height: 46px;
                }
                u{
                    color:$accent_color1;
                    span{
                        color: $white;
                    }
                }
            }
        }
        .newsletter_form{
            padding-top: 30px;
            form{
                > div{
                    display: inline-block;
                    vertical-align: top;
                    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        color: $theme_color1;
                    }
                    ::-moz-placeholder { /* Firefox 19+ */
                        color: $theme_color1;
                    }
                    :-ms-input-placeholder { /* IE 10+ */
                        color: $theme_color1;
                    }
                    :-moz-placeholder { /* Firefox 18- */
                        color: $theme_color1;
                    }
                    &.n_input_holder{
                        width: calc(100% - 200px);
                        padding-right: 7px;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            padding-right: 0;
                        }
                        .n_input{
                            outline: none;
                            font-weight: 700;
                            width: 100%;
                            height: 60px;
                            border: 2px solid $accent_color1;
                            background: $white;
                            padding: 0 15px;
                            font-size: 16px;
                            border-radius: 5px;
                            @media only screen and (max-width: 991px) {
                                text-align: center;
                            }
                        }
                    }
                    &.n_submit_btn{
                        height: 60px;
                        line-height: 56px;
                        width: 200px;
                        background: $accent_color2;
                        position: relative;
                        font-size: 16px;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        color: $white;
                        text-align: center;
                        border: 2px solid $accent_color2;
                        border-radius: 5px;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                        p{
                            display: inline-block;
                            padding-right: 51px;
                            background-repeat: no-repeat;
                            background-position: right center;
                            background-image: url("./../img/btn_arr.png");
                            font-weight: 700;
                        }
                        .n_submit{
                            @include fill;
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }
    .general_footer_top{
        //background: $theme_color4;
        background:#001c41;
        padding: 45px 0;
        @media only screen and (max-width: 1500px) {
            padding: 35px 0;
        }
        @media only screen and (max-width: 991px) {
            ul{
                li{
                    width: 50%;
                    display: inline-block;
                    vertical-align: top;
                }
            }
        }
        .fh{
            font-size: 16px;
            color: $white;
            margin-bottom: 30px;
            @media only screen and (max-width: 1500px) {
                font-size: 14px;
                margin-bottom: 20px;
            }
        }
        .logo_box{
            padding:0 0 30px 0;
            @media only screen and (max-width: 1500px) {
                padding: 0 0 20px 0;
            }
        }
        .mail_box{
            @media only screen and (max-width: 1200px) {
                margin-bottom: 30px;
            }
            a{
                color: $font_color3;
                padding: 5px 0 5px 46px;
                background-repeat: no-repeat;
                background-position: left center;
                background-image: url("./../img/fast_contact_icon.png");
                @include anim;
                font-size: 13px;
                &:hover{
                    text-decoration: none;
                    color: $accent_color1;
                }
            }
        }
        .footer_nav{
            width: 20%;
            display: inline-block;
            vertical-align: top;
            @media only screen and (max-width: 991px) {
                width: 100%;
                margin-bottom: 30px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            ul{
                li{
                    a{
                        color: $font_color3;
                        font-size: 13px;
                        line-height: 30px;
                        font-weight: 600;
                        @include anim;
                        @media only screen and (max-width: 1500px) {
                            font-size: 12px;
                            line-height: 29px;
                        }
                        &:hover{
                            color: $accent_color1;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
    .general_footer_bottom{
        padding: 40px 0;
        font-size: 11px;
        color: $font_color1;
        @media only screen and (max-width: 1500px) {
            padding: 25px 0;
        }
        @media only screen and (max-width: 991px) {
            text-align: center;
        }
        p,a{
            font-weight: 600;
            &.to_up{
                font-weight: 700;
                cursor: pointer;
                display: inline-block;
                @media only screen and (max-width: 991px) {
                    margin: 15px 0;
                }
            }
        }
        .copy_right{
            > div{
                display: inline-block;
                vertical-align: middle;
                &:last-child{
                    //padding-left: 15px;
                    padding-left: 2px;
                    @media only screen and (max-width: 991px) {
                        padding-left: 0;
                        margin-ottom: 10px;
                    }
                }
            }
        }
        ul{
            li{
                display: inline-block;
                vertical-align: middle;
                //margin-right: 25px;
                &:last-child{
                    margin-right: 0;
                }
                a{
                    font-size: 11px;
                    color:$font_color1;
                    @include anim;
                    padding-left: 20px;
                    &:hover{
                        color: $accent_color1;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.top{
    padding: 5px 0;
    @media only screen and (max-width: 991px) {
        display: none;
    }
    .nav_way{
        > ul{
            > li{
                div{
                    display: inline-block;
                }
                display: inline-block;
                vertical-align: top;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    line-height: 30px;
                }
                &:last-child{
                    a{
                        font-weight: 700;
                        color: #003580;
                    }
                    &:after{
                        display: none;
                    }
                }
                &:after{
                    content: '';
                    height: 11px;
                    width: 5px;
                    @include bg_pos;
                    background-image: url("./../img/separate.png");
                    display: inline-block;
                    margin: 0 14px 0 9px;
                    @media only screen and (max-width: 991px) {
                        transform: rotate(90deg);
                    }
                }
                a{
                    text-decoration: none;
                    color: $font_color2;
                    @include anim;
                    font-size: 13px;
                    &:hover{
                       color: $accent_color1;
                    }
                }
            }
        }
    }
}

.split_view{
    @media only screen and (max-width: 991px) {
        padding-top: 0px;
        .map_box{
            display: none;
        }
        > .filters{
            > form{
                > .criteria_filters{
                    display: none;
                }
            }
        }
        .searchingvalues{
            display: none;
        }
        .filtered_content{
            .section_header{
                .row{
                    &:first-child{
                        display: flex;
                        flex-flow: column;
                        > div{
                            align-items: stretch;
                            &.col-md-8{
                                order:2;
                            }
                            &.col-md-4{
                                order:1;
                            }
                        }
                    }
                }
            }
        }
    }
    > div{
        display: inline-block;
        vertical-align: top;
        &.filters{
            .small_header{
                font-weight: 600;
            }
            width: 264px;
            @media only screen and (max-width: 1200px) {
                width: 220px;
            }
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
            > form{
              > div{
                  margin-bottom: 10px;
              }
            }
            .search_box{
                padding: 17px;
                background: $accent_color1;
                border-radius: 5px;
                .holder{
                    margin-bottom: 5px;
                    border-bottom: none;
                    padding-bottom: 0;
                }
                .default_font{
                    margin-bottom: 10px;
                    h2{
                        font-weight: 700;
                    }
                }
            }
            .map_box{
                position: relative;
                width: 100%;
                height: 0;
                padding-bottom: 72%;
                @include bg_pos;
                background-size: cover;
                padding-top: 17px;
                padding-right: 17px;
                @media only screen and (max-width: 991px) {
                    padding-top: 0;
                    padding-right: 0;
                }
                iframe{
                    @include fill;
                }
            }
            .criteria_filters{
                padding:10px 17px 10px 17px;
                border: 1px solid $border_color;
                border-radius: 5px;
                > div{
                    &:first-child{
                        padding-bottom: 10px;
                        border-bottom: 1px solid $border_color;
                    }
                }
                .default_font{
                    margin-bottom: 10px;
                    h3{
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }
        &.filtered_content{
            width: calc(100% - 264px);
            padding: 5px 0 0 20px;
            > .section_header{
                h2{
                    margin-top: 10px;
                }
            }
            .section_header{
                div.default_font{
                    h3{
                        font-size: 23px;
                        font-weight: 700;
                    }
                }
            }
            @media only screen and (max-width: 1500px) {
                padding-left: 20px;
            }
            @media only screen and (max-width: 1200px) {
                width: calc(100% - 220px);
            }
            @media only screen and (max-width: 991px) {
                width: 100%;
                padding-left: 0;
            }
            .search_list{
                > div {
                    > ul {
                        > li {
                            margin-bottom: 10px;

                            article.search_box_list {
                                border: $border_color;
                                padding: 16px;
                                border: 1px solid $border_color;
                                border-radius: 5px;
                                .search_box_top {
                                    &.dew_view{
                                        > div{
                                            display: inline-block;
                                            vertical-align: top;
                                            &.image_holder{
                                                width: 200px;
                                                @media only screen and (max-width: 1200px) {
                                                    width: 100%;
                                                }
                                                .image{
                                                    @include bg_pos;
                                                    background-size: cover;
                                                    position: relative;
                                                    width: 100%;
                                                    height: 200px;
                                                    overflow: hidden;
                                                    border-radius: 5px;

                                                    .promoted {
                                                        background: $accent_color3;
                                                        color: $white;
                                                        display: inline-block;
                                                        padding: 0 15px;
                                                        display: inline-block;
                                                        font-size: 13px;
                                                        line-height: 30px;
                                                        //margin: 15px 0 0 15px;
                                                        border-radius: 5px;
                                                        margin: 5px 0 0 -5px;
                                                        transform: scale(0.8);
                                                    }

                                                    .fav {
                                                        position: absolute;
                                                        top: 15px;
                                                        right: 15px;
                                                        cursor: pointer;
                                                        width: 42px;
                                                        height: 42px;
                                                        border: 2px solid rgba(255, 255, 255, 0.2);
                                                        border-radius: 5px;
                                                        @include bg_pos;
                                                        background-image: url("./../img/star.png");
                                                        z-index: 2;
                                                        cursor: pointer;
                                                        @include anim;

                                                        &:hover {
                                                            border: 2px solid rgba(255, 255, 255, 1);
                                                        }

                                                        &.added {
                                                            background-color: $accent_color1;
                                                            border: $accent_color1;
                                                        }
                                                    }
                                                }
                                                .underphoto{
                                                    background: transparent;
                                                    padding: 0;
                                                    color: #000000;
                                                }
                                            }
                                        }
                                    }
                                    > div {
                                        display: inline-block;
                                        vertical-align: top;
                                        &.image {
                                            @include bg_pos;
                                            background-size: cover;
                                            position: relative;
                                            width: 200px;
                                            //height: 200px;
                                            height: 120px;
                                            overflow: hidden;
                                            border-radius: 5px;
                                            @media only screen and (max-width: 1200px) {
                                                width: 100%;
                                            }
                                            @media only screen and (max-width: 1200px) {
                                                background-size: contain;
                                                height: 0;
                                                padding-bottom: 64%;
                                            }
                                            .promoted {
                                                //background: $accent_color3;
                                                background:rgba(7, 157, 47, 0.8);

                                                color: $white;
                                                display: inline-block;
                                                padding: 0 15px;
                                                display: inline-block;
                                                font-size: 13px;
                                                line-height: 30px;
                                                //margin: 15px 0 0 15px;
                                                border-radius: 5px;
                                                margin: 5px 0 0 -5px;
                                                transform: scale(0.8);
                                            }

                                            .fav {
                                                position: absolute;
                                                //top: 15px;
                                                top: 4px;
                                                right: 15px;
                                                cursor: pointer;
                                                //width: 42px;
                                                //height: 42px;
                                                width: 32px;
                                                height: 32px;
                                                border: 2px solid rgba(255, 255, 255, 0.2);
                                                border-radius: 5px;
                                                @include bg_pos;
                                                background-image: url("./../img/star.png");
                                                z-index: 2;
                                                cursor: pointer;
                                                background-size: 17px;
                                                @include anim;

                                                &:hover {
                                                    border: 2px solid rgba(255, 255, 255, 1);
                                                }

                                                &.added {
                                                    background-color: $accent_color1;
                                                    border: $accent_color1;
                                                }
                                            }
                                        }

                                        &.desc {
                                            width: calc(100% - 200px);
                                            padding-left: 20px;
                                            color: $font_color1;
                                            &.dev_desc{
                                                width: 100%;
                                                padding-left: 0;
                                                margin-bottom: 0;
                                                padding-top: 0;
                                                header{
                                                    > div{
                                                        display: inline-block;
                                                        vertical-align: middle;
                                                        &.text_holder{
                                                            width: calc(100% - 200px);
                                                            @media only screen and (max-width: 991px) {
                                                                width: 100%;
                                                                margin-bottom: 10px;
                                                            }
                                                        }
                                                        &.logo_holder{
                                                            text-align: right;
                                                            width: 200px;
                                                            @media only screen and (max-width: 991px) {
                                                                width: 100%;
                                                                text-align: left;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            @media only screen and (max-width: 991px) {
                                                position: relative;

                                            }
                                            > div{
                                                display: inline-block;
                                                vertical-align: bottom;
                                                &.desc_left{
                                                    width: calc(100% - 160px);
                                                    min-height: 200px;
                                                    @media only screen and (max-width: 991px) {
                                                        width: 100%;
                                                        min-height: 0;
                                                        .default_font{
                                                            display: none;
                                                            padding: 10px 0;
                                                        }
                                                    }
                                                    .default_font{
                                                        @media only screen and (max-width: 991px) {
                                                            text-align: center;
                                                            line-height: 16px;
                                                            p{
                                                                margin-bottom: 0;
                                                            }
                                                        }
                                                        table{
                                                            line-height: 16px;
                                                            width: auto !important;
                                                        }
                                                    }
                                                }
                                                &.desc_right{
                                                    width: 160px;
                                                    @media only screen and (max-width: 991px) {
                                                        width: 100%;
                                                    }
                                                }
                                            }
                                            @media only screen and (max-width: 1200px) {
                                                width: 100%;
                                                padding-top: 15px;
                                                padding-left: 0;
                                            }
                                            @media only screen and (max-width: 991px) {
                                                //text-align: center;
                                                .text-right{
                                                    text-align: left;
                                                    .price{
                                                        display: none;
                                                    }
                                                }
                                            }

                                            > .default_font {
                                                font-weight: 600;
                                                line-height: 23px;
                                                p {
                                                    font-weight: inherit;
                                                }
                                            }

                                            header {
                                                margin-bottom: 5px;

                                                a {
                                                    @include anim;

                                                    &:hover {
                                                        color: $accent_color1;
                                                    }
                                                }

                                                h3 {
                                                    font-size: 21px;
                                                    line-height: 31px;
                                                    color: #003580;
                                                    font-weight: 800;
                                                    @media only screen and (max-width: 991px) {
                                                            line-height: 25px;
                                                    }
                                                    a {
                                                        font-weight: inherit;
                                                        color: inherit;

                                                        &:hover {
                                                            text-decoration: none;
                                                        }
                                                    }
                                                }

                                                ul {
                                                    li {
                                                        display: inline-block;
                                                        vertical-align: middle;

                                                        &:last-child {
                                                            &:after {
                                                                display: none;
                                                            }
                                                        }

                                                        &:after {
                                                            display: none;
                                                            content: '•';
                                                            //display: inline-block;
                                                            margin: 0 10px 0 5px;
                                                            @media only screen and (max-width: 991px) {
                                                                display: none;
                                                            }
                                                        }

                                                        a, .show_map {
                                                            font-size: 13px;
                                                            line-height: 23px;
                                                            color: #003580;
                                                            font-weight: 800;
                                                            font-weight: 600;
                                                            text-decoration: underline;
                                                            cursor: pointer;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .search_box_bottom {
                                    width: 100%;

                                    .price {
                                        margin-bottom: 5px;

                                        .default_font {
                                            color: $font_color1;
                                            font-weight: 600;
                                            line-height: 17px;
                                            p {
                                                font-weight: inherit;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.filters{
    .holder{
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $border_color;
        &:last-child{
            border-bottom: none;
        }
    }
}
.holder{
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $font_color1;
        margin-bottom: 2px;
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color: $font_color1;
    }
    :-ms-input-placeholder { /* IE 10+ */
        color: $font_color1;
    }
    :-moz-placeholder { /* Firefox 18- */
        color: $font_color1;
    }
    label{
        width: 100%;
        font-size: 13px;
        margin-bottom: 2px;
        color:$font_color1;
        font-weight: 600;
    }
    .select_holder{
        .chosen-container-single {
            .chosen-single{
                border: 1px solid $border_color;
                height: 40px;
                background: $white;
                box-shadow: none;
                padding: 0 20px;
                font-size: 13px;
                color: $font_color2;
                border-radius: 0;
                line-height: 40px;
                color: $font_color1;
                font-weight: 600;
                border-radius: 5px;
                div{
                    width: 50px;
                    b{
                        @include bg_pos;
                        background-image: url('./../img/select_arr3.png');
                    }
                }
            }
            .chosen-search{
                input{
                    //border: 1px solid $border_color;
                    color: #a4b1bf;
                }
            }
            .chosen-drop{
                //border: 1px solid $border_color;
                width: 100%;
                top: calc(100% - 2px);
                .highlighted{
                    // background: $accent_color;
                    color: $white;
                }
            }
            .chosen-results {
                li{
                    padding: 10px 20px;
                    color: #000000;
                }
            }
        }
    }
    .default_input{
        height: 40px;
        padding: 0 20px;
        outline: none;
        font-size: 13px;
        color: $font_color1;
        width: 100%;
        border: none;
        font-weight: 600;
        border-radius: 5px;
        &.with_border{
            border: 1px solid $border_color;
        }
    }
    .default_textarea{
        min-height: 130px;
        padding: 15px 20px;
        outline: none;
        font-size: 13px;
        color: $font_color1;
        width: 100%;
        border: none;
        font-weight: 600;
        outline: none;
        resize: none;
        border: 1px solid $border_color;
        border-radius: 5px;
    }
    .default_submit{
        width: 280px;
        height: 50px;
        font-size: 11px;
        text-transform: uppercase;
        color: $white;
        outline: none;
        border: none;
        background: $accent_color2;
        font-weight: 800;
        border-radius: 5px;
        border: 1px solid $accent_color1;

    }
    .filter_btn{
        @include anim;
        background: #8b8b8b;
        color: white;
        font-weight: 700;
        text-transform: uppercase;
        height: 50px;
        width: 100%;
        @include anim;
        border: none;
        margin-top: 10px;
        border-radius: 5px;
        &:hover{
            background: $accent_color2;
        }
    }
    .map_filter_ajax{
        @include anim;
        background: #8b8b8b;
        color: white;
        font-weight: 700;
        text-transform: capitalize;
        height: 50px;
        width: 100%;
        @include anim;
        border: none;
        margin-top: 10px;
        border-radius: 5px;
        &:hover{
            background: $accent_color2;
        }
    }
    .submit_btn{
        margin-top: 10px;
        height: 50px;
        line-height: 50px;
        width: 60%;
        margin-left: auto;
        background: $accent_color2;
        position: relative;
        font-size: 16px;
        letter-spacing: 1px;
        text-transform: capitalize;
        color: $white;
        text-align: center;
        border-radius: 5px;
        @media only screen and (max-width: 1200px) {
            width: 100%;
        }
        p{
            display: inline-block;
            padding-left: 36px;
            background-repeat: no-repeat;
            background-position: left center;
            background-image: url("./../img/search_icon.png");
            font-weight: 700;
        }
        .submit{
            @include fill;
            opacity: 0;
        }
    }
    .chosen-container-single {
        .chosen-single{
            border: none;
            height: 40px;
            background: $white;
            box-shadow: none;
            padding: 0 20px;
            font-size: 13px;
            color: $font_color1;
            border-radius: 0;
            line-height: 40px;
            color: $font_color1;
            font-weight: 600;
            border-radius: 5px;
            div{
                width: 50px;
                b{
                    @include bg_pos;
                    background-image: url('./../img/select_arr2.png');
                }
            }
        }
        .chosen-search{
            input{
                //border: 1px solid $border_color;
                color: #a4b1bf;
            }
        }
        .chosen-drop{
            //border: 1px solid $border_color;
            width: 100%;
            top: 100%;
            .highlighted{
                // background: $accent_color;
                color: $white;
            }
        }
        .chosen-results {
            li{
                padding: 10px 20px;
                color: #000000;
            }
        }
    }
    &.check_holder{
        padding-top: 3px;
        > div{
            display: inline-block;
            vertical-align: top;
            &.checkbox_holder{
                overflow: hidden;
                .checkbox_image_box{
                    width: 23px;
                    height: 23px;
                    border-radius: 5px;
                    border: 1px solid $white;
                    position: relative;
                    background: $white;
                    > div{
                        @include fill;
                        border: none;
                        border-radius: 0;
                        @include bg_pos;
                        background-size: cover;
                        &.yes{
                            &.on{
                                background-color: $white;
                                background-image: url("./../img/check1.png");
                            }
                        }
                    }
                }
            }
           &.checkbox_desc{
                width: calc(100% - 23px);
                padding-left: 11px;
                font-size: 13px;
                color: $font_color1;
                font-weight: 600;
               padding-top: 3px;
               p{
                   font-weight: inherit;
               }
            }
        }
    }
    .prices{
        display: table;
        width: 100%;
        margin-bottom: 20px;
        > div{
            display: table-cell;
            vertical-align: middle;
            height: 100%;
            .default_input{
                cursor: default;
            }
            &.separate{
                text-align: center;
                width: 25px;
            }
        }
    }
    .radio_list{
        ul{
            li{
                width: 100%;
                margin-bottom: 4px;
                &:last-child{
                    margin-bottom: 0;
                }
                > div{
                    display: inline-block;
                    vertical-align: top;
                    &.radio_holder{
                        width: 23px;
                        height: 23px;
                        border: 1px solid $border_color;
                        position: relative;
                        cursor: pointer;
                        margin-bottom: 4px;
                        @include bg_pos;
                        border-radius: 5px;
                        &.on{
                            background-image: url("./../img/radio_on.jpg");
                        }
                    }
                    &.radio_desc{
                        width: calc(100% - 23px);
                        padding-left: 15px;
                        label{
                            line-height: 23px;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    .check_list{
        ul{
            li{
                width: 100%;
                margin-bottom: 4px;
                &:last-child{
                    margin-bottom: 0;
                }
                div{
                    display: inline-block;
                    vertical-align: top;
                    &.checkbox_holder{
                        .checkbox_image_box{
                            width: 23px;
                            height: 23px;
                            border: 1px solid $border_color;
                            position: relative;
                            cursor: pointer;
                            margin-bottom: 4px;
                            @include bg_pos;
                            border-radius: 5px;
                            > div{
                                @include fill;
                                border: none;
                                border-radius: 0;
                                @include bg_pos;
                                background-size: cover;
                                &.yes{
                                    &.on{
                                        background-image: url("./../img/radio_on.jpg");
                                    }
                                }
                            }
                        }
                    }
                    &.checkbox_desc{
                        width: calc(100% - 23px);
                        padding-left: 15px;
                        label{
                            margin: 0;
                            line-height: 23px;
                        }
                    }
                }
            }
        }
    }
}

.ui-slider-horizontal{
    height: 3px;
}

.ui-widget-content{
    border: none;
    background: #c6c6c6;
}

.ui-slider {
    .ui-slider-handle{
        height: 17px;
        width: 10px;
        border: 1px solid $border_color;
        background: $white;
        margin: 0;
        outline: none;
        cursor: pointer;
    }
    .ui-slider-range{
        background: $accent_color1;
    }
}

.tags{
    margin-bottom: 5px;
    ul{
        li{
            display: inline-block;
            vertical-align: top;
            p{
                border-radius: 2px;
                font-size: 13px;
                line-height: 20px;
                color: $white;
                background: $accent_color1;
                padding: 0 9px;

            }
        }
    }
}

.details_nav{
    nav.nav{
        @media only screen and (max-width: 991px) {
            display: none;
        }
        > ul{
            padding: 5px 22px;
            background: #e9ecf0;
            border-bottom: 1px solid #cdd3db;
            border-radius: 5px;
            @media only screen and (max-width: 991px) {
                padding: 15px;
                background: #f5f5f5;
                border: none;
                border-radius: 0;
            }
           > li{
               display: inline-block;
               vertical-align: middle;
               padding-right: 15px;
               margin-right: 11px;
               border-right: 1px solid $white;
               @media only screen and (max-width: 1500px) {
                   padding-right: 10px;
                   margin-right: 10px;
               }
               @media only screen and (max-width: 991px) {
                    width: 100%;
                    padding: 15px 20px;
                    border: 1px solid #ececec;
                   background: $white;
               }
               &:last-child{
                   padding-right: 0px;
                   margin-right: 0px;
                   border-right: none
               }
               > a{
                   color: #4b5b6e;
                   padding-left: 27px;
                   font-weight: 600;
                   font-size:14px;
                   background-repeat: no-repeat;
                   background-position: left center;
                   @media only screen and (max-width: 1700px) {
                       font-size: 11px;
                   }
                   @media only screen and (max-width: 1500px) {
                       padding-left: 20px;
                       font-size: 12px;
                   }
                   @media only screen and (max-width: 991px) {
                        font-size: 14px;
                        padding-left: 27px;
                   }
               }
           }
        }
    }
    header{
        padding-top: 15px;
        margin-bottom: 15px;
        @media only screen and (max-width: 991px) {
            text-align: center;
        }
        .tags{
            margin-bottom: 10px;
            p{
                background:$accent_color3;
                font-size:14px;
                line-height: 24px;
                border-radius: 2px;
            }
        }
        a{
            @include anim;
            &:hover{
                color: $accent_color1;
            }
        }
        h2{
            color: #003580;
            font-weight: 800;
        }
       .col-md-7{
           > ul{
               display: none;
               padding-top: 10px;
               li{
                   display: inline-block;
                   vertical-align: middle;
                   &:last-child{
                       &:after{
                           display: none;
                       }
                   }
                   &:after{
                       display: none;
                       content: '•';
                       //display: inline-block;
                       margin: 0 10px 0 5px;
                   }
                   a,p{
                       display: inline-block;
                       font-size: 13px;
                       line-height: 23px;
                       color: #003580;
                       font-weight: 800;
                       font-weight: 600;
                       text-decoration: underline;
                   }
                   p{
                       text-decoration: none;
                       background-repeat: no-repeat;
                       background-position: left center;
                       padding-left: 30px;
                   }
               }
           }
       }
        .btns{
            padding-top: 25px;
            position: relative;
            ul{
                li{
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 15px;
                    @media only screen and (max-width: 991px) {
                        margin-right: 0;
                        &:nth-child(2){
                            width: calc(100% - 90px);
                        }
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    .btns_btn{
                        height: 45px;
                        width: 45px;
                        border: 1px solid $border_color;
                        border-radius: 5px;
                        @include bg_pos;
                        display: block;
                        @media only screen and (max-width: 991px) {
                            width: 45px;
                            height: 45px;
                        }
                        &.fav{
                            background-image: url("./../img/star2_icon.png");
                        }
                        &.share{
                            background-image: url("./../img/share_icon.png");
                        }
                        &.return{
                            background-image: url("./../img/a_close2.png");
                            background-color: #ec3d45;

                        }
                    }
                    .ask_btn{
                        cursor:pointer;
                        line-height: 38px;
                        padding-top: 0;
                        padding: 0 30px;
                        background-image: none;
                        background: $accent_color2;
                        color: $white;
                        @include anim;
                        &:hover{
                            color:$accent_color2;
                            background: $white;
                        }
                    }
                }
            }
        }
    }
}

.gallery{
    position: relative;
    .mobile_arr{
        display: none;
        @media only screen and (max-width: 991px) {
            display: block;
            position: absolute;
            top: 0;
            width: 30px;
            @include vertical;
            height: 100%;
            > div{
                width: 100%;
                text-align: center;
                font-size: 15px;
                background: rgba(255,255,255,0.8);
                height: 30px;
                line-height: 30px;
                p{
                    font-weight: 700;
                    color: #000000;
                }
            }
            &.arr_left{
                left: 0;

            }
            &.arr_right{
                right: 0;

            }
        }
    }
    > ul {
        white-space: nowrap;
        > li{
            display: inline-block;
            vertical-align: middle;
            width: 100%;
            margin-right: 20px;
            @media only screen and (max-width: 991px) {
                overflow: hidden;
                position: relative;
                .grid-layout{
                    white-space: nowrap;
                    position: relative;
                    display: block;
                    > li{
                        display: inline-block !important;
                        vertical-align: top;
                        width: 100%;
                        height: 0;
                        padding-bottom: 100%;
                        margin-right: 5%;
                    }
                }
            }
        }
    }
    .cover{
        @include fill;
        background: rgba(255,255,255,0.3);
        background: -moz-linear-gradient(left, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0.9) 60%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,255,255,0.3)), color-stop(60%, rgba(255,255,255,0.9)));
        background: -webkit-linear-gradient(left, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0.9) 60%);
        background: -o-linear-gradient(left, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0.9) 60%);
        background: -ms-linear-gradient(left, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0.9) 60%);
        background: linear-gradient(to right, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0.9) 60%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
    }
    .photos{
        line-height: 45px;
        font-size: 13px;
        font-weight: 700;
        color: $white;
        padding: 0 20px;
        border-radius: 5px;
        background: rgba(0,0,0,0.7);
        position: absolute;
        bottom: 20px;
        right: 20px;
        @include anim;
        @media only screen and (max-width: 991px) {
            display: none;
        }
        &:hover{
            background: rgba(0,0,0,1);;
          a{
              text-decoration: none ;
          }
        }
        a{
            color: inherit;
            display: inline-block;
            padding-left: 33px;
            background-position: left center;
            background-repeat: no-repeat;
            background-image: url("./../img/plus_icon.png");
        }
    }
}

.grid-layout {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-gap: 20px;
    grid-auto-rows: minmax(200px, auto);
    grid-auto-flow: dense;
    @media only screen and (max-width: 1500px) {
        grid-auto-rows: minmax(150px, auto);
    }
}

//box

.grid-item {
    @include bg_pos;
    background-size: cover;
    border-radius: 5px;
    position: relative;
}

// wielkosci boxów

.small{
    grid-column-end: span 1;
    grid-row-end: span 1;
}

.large {
    grid-column-end: span 2;
    grid-row-end: span 2;
}

.invest_info{
    padding: 20px 0;
    margin-bottom:5px;
    border-bottom: 1px solid $border_color;
    margin-bottom: 20px;
    background: #e9ecf0;
    @media only screen and (max-width: 991px) {
        padding: 0px 0 0 0;
        border: 1px solid $border_color;
        border-bottom: none;
    }
    .invest_box{
        text-align: center;
        font-size: 15px;
        line-height: 25px;
        color: $font_color2;
        padding-top: 50px;
        background-position: center top;
        background-repeat: no-repeat;
        @media only screen and (max-width: 1500px) {
            font-size: 13px;
            line-height: 23px;
        }
        @media only screen and (max-width: 991px) {
            margin-bottom: 20px;
            background-position: left 10px center;
            text-align: left;
            padding-left: 50px;
            padding-top: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid $border_color;
            margin-bottom: 0;
        }
        strong{
            color: $font_color1;
        }
    }
}

.apartaments_list{
    margin-bottom: 44px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 30px;
    }
    @media only screen and (max-width: 991px) {
        text-align: center;
        h4{
            font-size: 14px !important;
        }
    }
    .sub_header{
        margin-bottom: 35px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 30px;
        }
        > div{
            display: inline-block;
            vertical-align: middle;
            font-size: 13px;
            .show_all_offers{
                //text-decoration: underline;
                //color: $font_color2;
                cursor: pointer;
                @media only screen and (max-width: 991px) {
                    padding-left: 10px;
                }
            }
            &:first-child{
                h4{
                    font-weight: 400;
                }
            }
            &:last-child{
                margin-left: 25px;
                @media only screen and (max-width: 991px) {
                    margin-left: 0;
                }
            }
        }
    }
    .list_toggles{
        margin-bottom: 40px;
        > ul{
            > li{
                display: inline-block;
                vertical-align: top;
                margin-right: 10px;
                @media only screen and (max-width: 991px) {
                    margin-right: 0;
                    width: 100%;
                    margin-bottom: 10px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                &.toggle_active{
                    .toggle_btn{
                        border: $accent_color2;
                        background: $accent_color2;

                        .default_font{
                            h4,p{
                                color: $white;
                            }
                        }
                    }
                }
                .toggle_btn{
                    height: 70px;
                    width: 120px;
                    @include vertical;
                    border: 1px solid $border_color;
                    color: $border_color;
                    cursor: pointer;
                    border-radius: 5px;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                    > div.default_font{
                        width: 100%;
                        text-align: center;
                        line-height: 20px;
                        @media only screen and (max-width: 991px) {
                            h4{
                                &:after{
                                    content: ' pokojowe';
                                }
                            }
                        }
                        h4,p{
                            color: $font_color2;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
    .toggle_content{
        > table{
            > tbody{
                > tr{
                    display: none;
                }
            }
        }
        table{
            border-collapse: collapse;
            width: 100%;
            thead{
                background: #e9ecf0;
                border-radius: 10px;
                tr{
                    th{
                        padding: 17px 0;
                        font-size: 15px;
                        color: $font_color1;
                        text-align: center;
                        width: calc(100% / 9);
                        &:first-child { border-top-left-radius: 10px; }
                        &:last-child { border-top-right-radius: 10px; }
                        &:first-child { border-bottom-left-radius: 10px; }
                        &:last-child { border-bottom-right-radius: 10px; }

                        p{
                            font-weight: 600;
                        }
                        @media only screen and (max-width: 1500px) {
                            font-size: 11px;
                            &:first-child { border-top-left-radius: 7px; }
                            &:last-child { border-top-right-radius: 7px; }
                            &:first-child { border-bottom-left-radius: 7x; }
                            &:last-child { border-bottom-right-radius: 7px; }
                        }
                        @media only screen and (max-width: 991px) {
                            font-size: 8px;
                        }
                    }
                }
            }
            tbody{
                > tr{
                    &:last-child{
                        border-bottom: none;
                    }
                }
                tr{
                    border-bottom: 1px solid #e9ecf0;
                    td{
                        width: calc(100% / 9);
                        color: #616161;
                        font-size: 14px;
                        text-align: center;
                        @media only screen and (max-width: 1500px) {
                            font-size: 10px;
                        }
                        @media only screen and (max-width: 991px) {
                            font-size: 8px;
                        }
                        table{
                            width: 100%;
                            tr{

                               /* &:nth-child(odd){
                                    td{
                                        background: #ffffff;
                                    }
                                }
                                &:nth-child(even){
                                    td{
                                        background: #f5f6f7;
                                    }
                                }*/
                                td{
                                    padding: 20px 0;
                                }
                            }
                        }
                        .icon{
                            width: 24px;
                            height: 24px;
                            @include bg_pos;
                            background-size: 100% auto;
                            margin: 0 auto;
                        }
                        a{
                            color:#616161;
                            display: inline-block;
                            border: 1px solid #d6d6d6;
                            line-height: 30px;
                            padding: 7px 15px;
                            border-radius: 5px;
                            line-height: 17px;
                            &.ask_btn{
                                color:$accent_color2;
                                @include anim;
                                &:hover{
                                    background: $accent_color2;
                                    border: 1px solid $accent_color2;
                                    color: $white;
                                }
                                @media only screen and (max-width: 991px) {
                                    @include bg_pos;
                                    background-image: url("./../img/contact_icon3.png") !important;
                                    font-size: 0;
                                    border: none;
                                    padding: 0;
                                    width: 20px;
                                    border-radius: 0;
                                }
                            }
                            @media only screen and (max-width: 991px) {
                                line-height: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.invest_map, .street_view{
    margin-bottom: 10px;
    .map_holder{
        width: 100%;
        height: 0;
        padding-bottom: 32.6%;
        position: relative;
        overflow: hidden;
        border-radius: 5px;
        @media only screen and (max-width: 991px) {
            padding-bottom: 250px;
        }
        iframe{
            @include fill;
        }
    }
}

.sub_header_for_desc{
    color: $accent_color2;
    margin-bottom: 30px;
}

.invest_desc{
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $border_color;
}

.district_desc{
    margin-bottom: 60px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 40px;
    }
}

.deweloper_contact{
    border: 1px solid $border_color;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 20px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 30px;
    }
    @media only screen and (max-width: 991px) {
        display: none;
        padding: 15px;
    }
    .section_header{
        @media only screen and (max-width: 991px) {
            text-align: center;
        }
        h3{
            margin-bottom: 10px;
        }
        h4{
          strong{
              font-weight: 800;
              color: $accent_color3;
              margin-bottom:1px;
          }
        }
    }
    .dev_contact{
        div.contact_top{
            ul{
                li{
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 90px;
                    @media only screen and (max-width: 991px) {
                        margin-right: 0;
                        width: 100%;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
            .dev_contact_box{
                padding-left: 39px;
                background-position: left top;
                background-repeat: no-repeat;
                margin-bottom: 10px;
                @media only screen and (max-width: 991px) {
                    padding-left: 0;
                    background-position: center top;
                    padding-top: 39px;
                    text-align: center;
                    margin-bottom: 15px;
                }
                &.adress{
                    background-image: url("./../img/dev_icon1.png");
                }
                &.open{
                    background-image: url("./../img/dev_icon2.png");
                }
                &.more_flats{
                    background-image: url("./../img/dev_icon3.png");
                }
                div.default_font{
                    font-weight: 600;
                    line-height: 23px;
                    a{
                        color: $font_color1;
                        text-decoration: none;
                    }
                    p,a{
                        font-weight: inherit;
                    }
                }
            }
        }
        div.contact_bottom{
            margin: 20px 0;
            @media only screen and (max-width: 991px) {
                margin: 0 0 20px 0;
            }
            ul{
                li{
                    display: inline-block;
                    vertical-align: middle;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                    &.phone{
                        background-repeat: no-repeat;
                        background-position: left top;
                        background-image: url("./../img/dev_icon4.png");
                        padding-left: 37px;
                        margin-right: 50px;
                        @media only screen and (max-width: 991px) {
                            margin-right: 0;
                            padding-left: 0;
                            padding-top: 37px;
                            background-position: center top;
                            margin-bottom: 15px;
                        }
                        a{
                            font-size: 15px;
                            color: $font_color1;
                        }
                    }
                    &.mail{
                        a{
                            color: $white;
                            font-size: 11px;
                            text-transform: uppercase;
                            display: inline-block;
                            padding: 0 40px;
                            line-height: 40px;
                            background: $accent_color3;
                            border-radius: 5px;
                        }
                    }
                }
            }
        }
    }
}

.dev_desc{
    margin-bottom: 20px;
    .default_font{
        line-height: 24px;
    }
}
.contact_form{
    font-size: 13px;
    line-height: 24px;
    color: $font_color1;
    background:rgba(241, 244, 248, 0.3);
    .logo{
        margin-bottom: 20px;
        @media only screen and (max-width: 991px) {
            text-align: center;
        }
        img{
           // max-width: 110px;
        }
    }
    .form_desc{
        margin: 5px 0px;
    }
    padding: 20px;
    border: 1px solid #e5e9ee;
    -webkit-box-shadow: 0px 0px 12px 6px rgba(241,244,248,1);
    -moz-box-shadow: 0px 0px 12px 6px rgba(241,244,248,1);
    box-shadow: 0px 0px 12px 6px rgba(241,244,248,1);
    border-radius: 5px;
    .row{
        margin-bottom: 10px;
    }
    @media only screen and (max-width: 991px) {
        padding: 15px;
    }
}

.login_and_register{
    padding: 30px 0 70px 0;
    margin:2px;
    margin-bottom: 5px;
    @media only screen and (max-width: 991px) {
        padding: 30px 0;
    }
    .box_holder{
        //padding: 0 10%;
        @media only screen and (max-width: 1500px) {
            padding: 0;
        }
    }
    .lr_box{
        border: 1px solid #e5e9ee;
        -webkit-box-shadow: 0px 0px 12px 6px rgba(241,244,248,1);
        -moz-box-shadow: 0px 0px 12px 6px rgba(241,244,248,1);
        box-shadow: 0px 0px 12px 6px rgba(241,244,248,1);
        padding: 25px 30px;
        text-align: center;
        font-size: 13px;
        line-height: 23px;
        a.back_btn{
            padding-left: 32px;
            display: inline-block;
            background-repeat: no-repeat;
            background-position: left center;
            background-image: url("./../img/back_arr.png");
            margin-bottom: 30px;
        }
        .section_header{
            margin-bottom: 15px;
            p{
                color: $font_color2;
                font-weight: 600;
                padding-top: 15px;
                font-size: 13px;
                line-height: 17px;
            }
        }
        .holder{
            text-align: left;
            margin-bottom: 10px;
            label{
                font-size: 13px;
                font-weight: 400;
            }
            .default_input{
                height: 50px;
            }
            .default_submit{
                width: 100%;
                margin-top: 25px;
            }
        }
        .fb_header{
            position: relative;
            margin: 20px 0 30px 0;
            color: $font_color2;
            &:after{
                content: '';
                position: absolute;
                top: 45%;
                left: 0;
                width: 100%;
                border-bottom: 1px solid $border_color;
            }
            p{
                display: inline-block;
                padding: 0 19px;
                position: relative;
                z-index: 2;
                background: $white;
            }
        }
        .fb_login_btn{
            border: 1px solid #4267b1;
            color: #4267b1;
            display: block;
            margin-bottom: 30px;
            p{
                line-height: 50px;
                display: inline-block;
                padding-left: 40px;
                background-position: left center;
                background-repeat: no-repeat;
                background-image: url("./../img/fb_icon.png");
                font-weight: 700;
            }
        }
        .go_to_register{
           p{
               font-weight: 700;
               color: $font_color1;
               a{
                   color: #0077cc;
                   font-weight: inherit;
               }
           }
        }
    }
    .rules{
        padding-top: 35px;
        font-size: 13px;
        line-height: 23px;
        color: $font_color2;
        a{
            color: $accent_color2;
            font-weight: 700;
        }
    }
}

.add_classifieds{
    @media only screen and (max-width: 991px) {
        padding-top: 30px;
    }
    .classifieds_form{
        .row{
            margin-bottom: 30px;
            @media only screen and (max-width: 991px) {
                margin-bottom: 0;
            }
        }
        .sub_header{
            color: $font_color1;
            span{
                color: #ec3d45;
            }
        }
        .holder{
            @media only screen and (max-width: 991px) {
                margin-bottom: 15px;
            }
            textarea.default_input{
                min-height: 170px;
                outline: none;
                resize: none;
                width: 100%;
                border: 1px solid $border_color;
                padding: 10px 20px;
                border-radius: 5px;
                font-size: 13px;
            }
            .default_input{
                height: 50px;
            }
            > span{
                font-size: 10px;
                color: #b2b2b2;
            }
            .select_holder{
                .chosen-container-single {
                    .chosen-single {
                        height: 50px;
                        line-height: 50px;
                        font-size: 13px;
                    }
                }
            }
        }
    }
    .add_photos{
        margin-bottom: 50px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 20px;
        }
        ul{
            margin: 0 -10px;
            @media only screen and (max-width: 991px) {
                padding-top: 20px;
            }
            li{
                display: inline-block;
                vertical-align: top;
                padding: 0 10px;
                width: calc(100% / 8);
                @media only screen and (max-width: 991px) {
                    width: 50%;
                    margin-bottom: 20px;
                }
                .photo_box{
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                    border: 1px dashed $border_color;
                    background:#f8f8f8;

                    position: relative;
                    overflow: hidden;
                    &.main_photo{
                        background: #fbf2dd;
                    }
                    .icon_holder{
                       @include fill;
                        @include vertical;
                        > div{
                            width: 100%;
                            text-align: center;
                        }
                    }
                    .myform2_dropzone_box{
                        opacity: 0;
                        @include fill;
                        .previewsContainer {
                            .row {
                                > div.thumb {
                                    img{
                                        @include fill;
                                    }
                                }
                            }
                        }
                        .dropzone_box{
                            @include fill;
                        }
                    }
                }
            }
        }
    }
}

.sent_btn{
    position: relative;
    color: $white;
    font-size: 16px;
    line-height: 50px;
    background: $accent_color2;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 50px;
    border-radius: 5px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 20px;
    }
    p{
        font-weight: 800;
        display: inline-block;
        padding-left: 33px;
        background-repeat: no-repeat;
        background-position: left center;
        background-image: url("./../img/plus2.png");
    }
    input{
        opacity: 0;
        @include fill;
    }
}

.map_popup{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background:rgba(0,0,0,0.7);
    z-index: 9999;
    padding: 40px;
    @media only screen and (max-width: 991px) {
        padding: 0;
    }
    .close_map{
        position: absolute;
        top: -23px;
        right: -23px;
        cursor: pointer;
        @media only screen and (max-width: 991px) {
            top: 0;
            right: 0;
            z-index: 6;
            img{
                //width: 51px;
                width: 32px;
            }
        }
    }
    .toggle_full_view{
        position: absolute;
        left: 100%;
        top: 120px;
        width: 26px;
        height: 26px;
        border: 1px solid $border_color;
        border-left: none;
        background: $white;
        cursor: pointer;
        z-index: 10;
        overflow: visible !important;
        @include bg_pos;
        &.for_filters{
            background-image: url("./../img/map_arr_left.jpg");
        }
        &.for_map{
            left: 0;
            display: none;
            background-image: url("./../img/map_arr_right.jpg");
        }
    }
    .map_content{
        height: 100%;
        width: 100%;
        display: flex;

        //overflow: hidden;
        @media only screen and (max-width: 991px) {
            display: block;
            position: relative;
        }
        > div{

            &.filters_side{
                position: relative;
                border-top-left-radius: 7px;
                border-bottom-left-radius: 7px;
                //width: 40%;
                //width: 800px;
                width: 710px;
                padding: 10px;
                background: $white;
                display: flex;
                height: 100%;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    z-index: 1;
                    display: none;
                    overflow-y: scroll;
                    .criteria_filters{
                        padding-bottom: 62px !important;
                        > div.holder{
                            &:last-child{
                                padding: 0 30px;
                                position: fixed;
                                bottom: 14px;
                                left: 0;
                                width: 100%;
                                .map_filter_ajax{
                                    background: #0071c1;
                                }
                            }
                        }
                    }
                    &.f_open{
                        display: block;
                        padding-top: 62px;
                    }
                }
                > div{
                    overflow-y: scroll;
                    margin-right: 12px;
                    padding-right: 3px;
                    @media only screen and (max-width: 991px) {
                        margin-right: 0;
                        overflow: hidden;
                    }
                    &.filters{
                        width: 40%;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            .search_box{
                                .holder{
                                    display: none;
                                    &:nth-child(2){
                                        display: block;
                                    }
                                    &:nth-child(3){
                                        display: block;
                                    }
                                }
                            }
                        }
                        .criteria_filters{
                            padding: 7px 20px;
                            border: 1px solid $border_color;
                            border-radius: 5px;
                            .default_font{
                                padding-bottom: 5px;
                                margin-bottom: 5px;
                            }
                        }
                        .holder{
                            padding-bottom: 5px;
                            margin-bottom: 5px;
                            .checkbox_image_box{
                                margin-bottom: 0 !important;
                            }
                        }
                    }
                    &.list_side_by_developer{
                        width: 100% !important;
                    }
                    &.list_side{
                        margin-right: 0;
                        width: 60%;
                        border-top-right-radius: 7px;
                        border-bottom-right-radius: 7px;
                        @media only screen and (max-width: 991px) {
                            display: none;
                        }
                        > ul{
                            padding: 20px 10px;
                            border: 1px solid $border_color;
                            border-radius: 5px;
                            > li{
                                //margin-bottom: 14px;
                                margin-bottom: 10px;
                                padding-bottom: 10px;
                                border-bottom: 1px solid #d6d6d6;
                                &:last-child{
                                    margin-bottom: 0;
                                    margin-bottom: 10px;
                                }
                                &:hover{
                                    a{
                                        text-decoration: none;
                                        .small_offer_box{
                                            background: #f0f4fa;
                                        }
                                    }
                                }
                                .small_offer_box{
                                    display: flex;
                                    min-height: 180px;
                                    width: 100%;
                                    @include anim;
                                    border-radius: 5px;
                                    > div{
                                        &.image{
                                            width: 180px;
                                            border-radius: 5px;
                                            height: 120px;
                                        }
                                        &.desc{
                                            //padding:0px 14px;
                                            padding-left: 14px;
                                            width: calc(100% - 180px);
                                            header{
                                                a{
                                                    font-weight: 800;
                                                    line-height: 22px;
                                                    display: inline-block;
                                                    color: #003580;
                                                }
                                            }
                                            .tags{
                                               // display: none;
                                                p{
                                                    display: inline-block;
                                                    font-size: 13px;
                                                    line-height: 20px;
                                                    color: #ffffff;
                                                    background: $accent_color3;
                                                    padding: 0 9px;
                                                    border-radius: 2px;
                                                }
                                            }
                                            header, .adres{
                                                color: #003580;
                                            }
                                            .adres, .short_desc{
                                                font-size: 11px;
                                                line-height: 17px;
                                                p{
                                                    font-weight: 600;
                                                }
                                            }
                                            .adres{
                                                text-decoration: underline;
                                                margin: 7px 0;
                                            }
                                            .short_desc{
                                                color:$font_color1;
                                                margin-bottom: 10px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.filters_side_by_developer {
                width: 490px;
            }
            &.side_map_by_developer{
                width: calc(100% - 490px);
            }
            &.side_map{
                //width: 60%;
                //width: calc(100% - 800px);
                width: calc(100% - 710px);
                //width:50%;
                position: relative;
                @media only screen and (max-width: 991px) {
                    @include fill;
                }
                iframe{
                    @include fill;
                }
                .legend{
                    color:$font_color1;
                    position: absolute;
                    left: 8px;
                    bottom: 17px;
                    padding: 13px 24px;
                    background: $white;
                    width: 191px;
                    .legend_toggle_btn{
                        font-size: 16px;
                        position: relative;
                        padding: 10px 0;
                        margin-bottom: 10px;
                        cursor: pointer;
                        &.hiddnen_legend{
                            margin-bottom: 0;
                            .icon{
                                -moz-transform: scale(1, -1);
                                -webkit-transform: scale(1, -1);
                                -o-transform: scale(1, -1);
                                -ms-transform: scale(1, -1);
                                transform: scale(1, -1);
                            }
                        }
                        .icon{
                            position: absolute;
                            top: 0;
                            right: 0;
                            height: 100%;
                            width: 50px;
                            background-repeat: no-repeat;
                            background-position: right center;
                            background-image: url("./../img/legend_arr.png");
                        }
                    }
                    ul{
                        li{
                            font-size: 13px;
                            line-height: 25px;
                            padding-left: 40px;
                            background-position: left center;
                            background-repeat: no-repeat;
                            margin-bottom: 7px;
                            p{
                                font-weight: 600;
                            }
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.big_map_popup{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background:rgba(0,0,0,0.7);
    z-index: 9999;
    padding: 40px;
    .close_map{
        position: absolute;
        top: -23px;
        right: -23px;
        cursor: pointer;
    }
    .toggle_full_view{
        position: absolute;
        left: 100%;
        top: 120px;
        width: 26px;
        height: 26px;
        border: 1px solid $border_color;
        border-left: none;
        background: $white;
        cursor: pointer;
        z-index: 10;
        overflow: visible !important;
        @include bg_pos;
        &.for_filters{
            background-image: url("./../img/map_arr_left.jpg");
        }
        &.for_map{
            left: 0;
            display: none;
            background-image: url("./../img/map_arr_right.jpg");
        }
    }
    .big_map_content{
        height: 100%;
        width: 100%;
        display: flex;

        //overflow: hidden;
        > div{

            &.side_map{
                //width: 60%;
                //width: calc(100% - 780px);
                width:50%;
                position: relative;
                iframe{
                    @include fill;
                }
                .legend{
                    color:$font_color1;
                    position: absolute;
                    left: 8px;
                    bottom: 17px;
                    padding: 13px 24px;
                    background: $white;
                    width: 191px;
                    .legend_toggle_btn{
                        font-size: 16px;
                        position: relative;
                        padding: 10px 0;
                        margin-bottom: 10px;
                        cursor: pointer;
                        &.hiddnen_legend{
                            margin-bottom: 0;
                            .icon{
                                -moz-transform: scale(1, -1);
                                -webkit-transform: scale(1, -1);
                                -o-transform: scale(1, -1);
                                -ms-transform: scale(1, -1);
                                transform: scale(1, -1);
                            }
                        }
                        .icon{
                            position: absolute;
                            top: 0;
                            right: 0;
                            height: 100%;
                            width: 50px;
                            background-repeat: no-repeat;
                            background-position: right center;
                            background-image: url("./../img/legend_arr.png");
                        }
                    }
                    ul{
                        li{
                            font-size: 13px;
                            line-height: 25px;
                            padding-left: 40px;
                            background-position: left center;
                            background-repeat: no-repeat;
                            margin-bottom: 7px;
                            p{
                                font-weight: 600;
                            }
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.filters_side > div::-webkit-scrollbar {
    width: 14px;
}

.filters_side > div::-webkit-scrollbar-track {
   background: #f1f1f1;
}

.filters_side > div::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    outline: none;
}

@media only screen and (max-width: 991px) {
    .text-right{
        text-align: center;
    }
}

.rwd_filters_toggle{
    padding-top: 30px;
    font-size: 18px;
    color: $font_color1;
    text-transform: uppercase;
    @media only screen and (min-width: 991px) {
        display: none;
    }
    p{
        font-weight: 800;
    }
    .btn_holder{
        display: none;
        text-align: right;
        .filter_btn{
            display: inline-block;
            padding: 0 23px;
            background: $accent_color2;
            color: $white;
            font-size: 13px;
            line-height: 30px;
            cursor: pointer;
        }
    }
}

.advertisement{

    .advertisement_box{
        margin-top: 30px;
        padding: 17px;
        border: 1px solid $border_color;
        position: relative;
        border-radius: 5px;
        position: relative;
        .ad_btn{
            position: absolute;
            bottom:17px;
            right: 17px;
            @media only screen and (max-width: 991px) {
                position: relative;
                bottom: auto;
                right: auto;
            }
        }
        &.advertisement_box2{
            header{
                h2{
                    font-size: 20px;
                }
            }
            > div{
                &.image{
                    width: 250px;
                    @media only screen and (max-width: 1200px) {
                        width: 100%;
                    }
                }
                &.desc{
                    .desc_left{
                        width: 100%;
                    }
                }
            }
        }
        .advertisement_close{
            position: absolute;
            top: 17px;
            right: 17px;
            cursor: pointer;
            z-index: 2;
            width:27px;
            @media only screen and (max-width: 1200px) {
                background: $white;
            }
        }
        > div{
            display: inline-block;
            vertical-align: top;
            &.image{
                width: 300px;
                height: 175px;
                @include bg_pos;
                background-size: contain;
                @media only screen and (max-width: 1200px) {
                    width: 100%;
                   // height: 300px;
                }
                @media only screen and (max-width: 991px) {
                    height: 0;
                    padding-bottom: 56%;
                }
            }
            &.desc{
                width: calc(100% - 300px);
                padding-left: 20px;
                @media only screen and (max-width: 1200px) {
                    padding-left: 0;
                    padding-top: 20px;
                    width: 100%;
                }
                .tags{
                    margin-bottom: 10px;
                    ul{
                        li{
                            p{
                                background: $accent_color3;
                            }
                        }
                    }
                }
                header{
                    margin-bottom: 20px;
                    a{
                        @include anim;
                        &:hover{
                            color: $accent_color1;
                        }
                    }
                    h2{
                        color: #003580;
                        font-weight: 800;
                        a{
                            font-weight: inherit;
                            color: inherit;
                            &:hover{
                                text-decoration: none;
                            }
                        }
                    }
                    ul{
                        li{
                            display: inline-block;
                            vertical-align: middle;
                            &:last-child{
                                &:after{
                                    display: none;
                                }
                            }
                            &:after{
                                display: none;
                                content: '•';
                                //display: inline-block;
                                margin: 0 10px 0 5px;
                                @media only screen and (max-width: 991px) {
                                    display: none;
                                }
                            }
                            a, .show_map{
                                font-size: 13px;
                                line-height: 23px;
                                color: #003580;
                                font-weight: 800;
                                font-weight: 600;
                                text-decoration: underline;
                                cursor: pointer;
                            }
                            @media only screen and (max-width: 991px) {
                                .show_map{
                                    display: none;
                                }
                            }
                        }
                    }
                }
                .default_font{
                    line-height: 24px;
                    p{
                        font-weight: 600;
                    }
                }
                > div{
                    display: inline-block;
                    vertical-align: top;
                    &.desc_left{
                        width: 65%;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                        .default_font{
                            p{
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                    &.desc_right{
                        width: 35%;
                        //padding-left: 30px;
                        @media only screen and (max-width: 1300px) {
                            padding-left: 0;
                        }
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            padding-left: 0;
                            padding-top: 30px;
                        }
                        .ask_btn{
                            cursor:pointer;
                            //margin-left: 67px;
                            line-height: 38px;
                            padding-top: 0;
                            padding: 0 30px;
                            background-image: none;
                            background: #0071c1;
                            color: #ffffff;
                            margin-bottom: 30px;

                            @media only screen and (max-width: 1700px) {
                                margin-left: 0;
                            }
                            @media only screen and (max-width: 991px) {
                                margin-left: 0;
                                width: 100%;
                                text-align: center;
                            }
                        }
                        ul{
                            text-align: left;
                            li{
                                width: 100%;
                                > div{
                                    display: inline-block;
                                    vertical-align: top;
                                    font-size: 13px;
                                    line-height: 23px;
                                    @media only screen and (max-width: 991px) {
                                        &:last-child{
                                            text-align: right;
                                        }
                                    }
                                    p{
                                        font-weight: 600;
                                        color: $font_color1;
                                    }
                                    width: 50%;
                                    strong{
                                        font-weight: 800;
                                        color: $accent_color2;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

p.err{
    border: 1px solid red;
    border-radius: 5px;
    padding: 5px;
    background-color: rgba(255, 0, 0, 0.3);
    font-weight: 700;
    color: red;
    margin-top:5px;
    font-size:10px;
}


.dashboard{
    padding: 21px 0 100px 0;
    background: #e9f0fa;
    @media only screen and (max-width: 1500px) {
        padding: 21px 0 50px 0;
    }
    @media only screen and (max-width: 991px) {
        padding: 21px 0 40px 0;
    }
    .dashboard_split_view{
        > div{
            display: inline-block;
            vertical-align: top;
            &.dashboard_left_side{
                width: 305px;
                background: $white;
                margin-bottom: 30px;
                border-radius: 5px;
                @media only screen and (max-width: 1200px) {
                    width: 205px;
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-bottom: 21px;
                }
                .dashboard_nav{
                    > ul{
                        > li{
                            > a{
                                font-size: 13px;
                                line-height: 53px;
                                color: $theme_color1;
                                font-weight: 600;
                                padding: 0 17px;
                                display: block;
                                @include anim;
                                &:hover{
                                    background: $accent_color1;
                                    color: $white;
                                    text-decoration: none;
                                }
                            }
                            > a.active{
                                background: $accent_color1;
                                color: $white;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
            &.dashboard_right_side{
                width: calc(100% - 305px);
                padding-left: 37px;
                border-radius: 5px;
                overflow: hidden;
                @media only screen and (max-width: 1200px) {
                    width: calc(100% - 205px)
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    padding-left: 0;
                }
                .dashboard_content{
                    background: $white;
                    padding-bottom: 60px;
                    @media only screen and (max-width: 1200px) {
                        padding-bottom: 40px;
                    }
                    .dashboard_header{
                        background:#fbfbfb;
                        padding: 34px;
                        border-bottom: 2px solid #e9f0fa;
                        color: $font_color1;
                        margin-bottom: 45px;
                        @media only screen and (max-width: 1200px) {
                            margin-bottom: 20px;
                        }
                        @media only screen and (max-width: 991px) {
                            text-align: center;
                            margin-bottom: 21px;
                        }
                        h2{
                            font-weight: inherit;
                            font-size: 20px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
    }
    .dashboard_list{
        padding: 0px 35px;
        @media only screen and (max-width: 1200px) {
            padding: 0 20px;
        }
        > ul{
           > li{
                width: 100%;
                display: flex;
                border: 1px solid $border_color;
               margin-bottom: 16px;
               @media only screen and (max-width: 991px) {
                   display: block;
               }
               &:last-child{
                   margin-bottom: 0;
               }
                > div{
                    &.list_left_side{
                        width: calc(100% - 275px);
                        padding: 22px 25px;
                        @media only screen and (max-width: 1200px) {
                            padding: 20px 15px;
                        }
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                        > div{
                            display: inline-block;
                            vertical-align: top;
                            &.image{
                                width: 135px;
                                height: 120px;
                                background-size: cover;
                                @include bg_pos;
                                @media only screen and (max-width: 991px) {
                                    width: 100%;
                                    height: 0;
                                    padding-bottom: 90%;
                                    margin-bottom: 15px;
                                }
                            }
                            &.desc{
                                width: calc(100% - 135px);
                                padding-top: 25px;
                                padding-left: 30px;
                                color: $theme_color1;
                                font-size: 13px;
                                line-height: 23px;
                                @media only screen and (max-width: 1200px) {
                                    padding-left: 15px;
                                }
                                @media only screen and (max-width: 991px) {
                                    padding-left: 0;
                                    width: 100%;
                                    text-align: center;
                                }
                                h3{
                                    margin-bottom: 10px;
                                    color: $theme_color1;
                                    a{
                                        color: $theme_color1;
                                    }
                                }
                                p{
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                    &.list_right_side{
                        border-left: 1px solid $border_color;
                        width: 275px;
                        @include vertical;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            border-left: none;
                            padding-bottom: 20px;
                        }
                        .btns_holder{
                            width: 100%;
                            text-align: center;
                            ul{
                                li{
                                    display: inline-block;
                                    vertical-align: middle;
                                    margin-right: 27px;
                                    &:last-child{
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.ask_popup, .ask_popup_advanced{
    @media only screen and (max-width: 991px) {
        .checkbox_desc{
            width: calc(100% - 30px) !important;
        }
    }
    .logo_box{
        margin-bottom: 15px;
    }
    .holder{
        margin-bottom: 13px !important;
        @media only screen and (max-width: 991px) {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
    .popup_top{
        position: relative;
        img{
            position: absolute;
            right: 0;
            top: 0;
        }
        p{
            padding: 0 20px;
            width: 100%;
            color: $white;
            line-height: 50px;
            font-weight: 600;
        }
    }
    .check_list{
        ul{
            li{
                padding-bottom: 0 !important;
                .checkbox_image_box{
                    width: 23px !important;
                    height: 23px !important;
                }
            }
        }
    }
    .radio_list{
        margin-bottom: 23px;
        ul{
            li{
                display: inline-block;
                vertical-align: top;
                margin-right: 15px;
                @media only screen and (max-width: 991px) {
                    margin-right: 0;
                    width: 100%;
                    margin-bottom: 13px;
                }
                &:last-child{
                    margin-right: 0;
                    margin-bottom: 0;
                }
                > div{
                    display: inline-block;
                    vertical-align: top;
                    &.radio_holder{
                        width: 23px;
                        height: 23px;
                        border: 1px solid $border_color;
                        position: relative;
                        cursor: pointer;
                        margin-bottom: 4px;
                        @include bg_pos;
                        border-radius: 5px;
                        &.on{
                            background-image: url("./../img/radio_on.jpg");
                        }
                    }
                    &.radio_desc{

                        padding-left: 15px;
                        label{
                            line-height: 23px;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

.preferences_popup{
    .popup_top{
        > div{
            display: inline-block;
            vertical-align: middle;
            &.titlee{
                width: calc(100% - 100px);
                line-height: 50px;
                padding-left: 20px;
                color:$white;
                p{
                    font-weight: 600;
                }
            }
            &.closee{
                width: 100px;
            }
        }
    }
}

.dashboard_form, .preferences_popup, .ask_popup, .ask_popup_advanced{
    .holder{
        margin-bottom: 18px;
        @media only screen and (max-width: 991px) {
            padding: 0 15px;
        }
        > div{
            display: inline-block;
            vertical-align: top;
            &.label_holder{
                width: 120px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                label{
                    line-height: 50px;
                    font-size: 13px;
                    font-weight: 400;
                    color: $font_color1;
                }
            }
            &.input_holder{
                width: calc(100%  - 120px);
                padding-right: 20px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    padding-right: 0;
                }
            }
        }
        .chosen-container-single {
            .chosen-single{
                border: 1px solid $border_color;
                height: 50px;
                background: $white;
                box-shadow: none;
                padding: 0 20px 0 20px;
                font-size: 13px;
                color: #a4b1bf;
                border-radius: 0;
                line-height: 50px;
                color: $font_color1;
                font-weight: 600;
                background-repeat: no-repeat;
                background-position: left 30px center;
                border-radius: 5px;
                div{
                    width: 50px;
                    b{
                        @include bg_pos;
                        background-image: url('./../img/select_arr.png');
                    }
                }
            }
            .chosen-search{
                input{
                    border: 1px solid $border_color;
                    color: #a4b1bf;
                }
            }
            .chosen-drop{
                //border: 1px solid $border_color;
                border-top: none;
                left: 0;
                border: 1px solid $border_color;
                border-top: 0;
                .highlighted{
                    // background: $accent_color;
                    color: $white;
                }
            }
            .chosen-results {
                li{
                    padding: 10px 20px;
                    color: $font_color1;
                }
            }
        }
    }
    input{
        &.input{
            height: 50px;
            width: 100%;
            border: 1px solid $border_color;
            padding: 0 20px;
            outline: none;
            border-radius: 5px;
            font-size: 13px;
        }
        &.dashboard_submit{
            height: 50px;
            width: 100%;
            color: $white;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 700;
            background: #0077cc;
            border: none;
            border-radius: 5px;
        }
    }
    .textarea{
        outline: none;
        resize: none;
        min-height: 217px;
        width: 100%;
        border: 1px solid $border_color;
        padding: 10px 20px;
        border-radius: 5px;
        font-size: 13px;
    }
    .myform2_dropzone_box{
        border-radius: 5px;
        border: 1px solid $border_color;
        .dropzone_box{
            padding: 0 20px 0 0;
            height: 50px;
            line-height: 50px;
            font-size: 13px;
            color:$font_color1
        }
        .previewsContainer {
            .row {
                > div {
                    vertical-align: middle;
                    &.name{
                        font-size: 13px;
                        line-height: 23px;
                    }
                }
            }
        }
    }
    .delete_acc{
        font-size: 13px;
        margin-top: 15px;
        color: $font_color1;
        @include anim;
        display: inline-block;
        &:hover{
            color: red;
            text-decoration: none;
        }
    }
}

.preferences_popup, .ask_popup, .ask_popup_advanced{
    .holder{
        > div{
            width: calc(50% - 4px);
            margin-right: 8px;
            &:last-child{
                margin-right: 0;
            }
        }
    }
}

/* width */
.preferences_popup::-webkit-scrollbar, .ask_popup::-webkit-scrollbar, .ask_popup_advanced::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.preferences_popup::-webkit-scrollbar-track, .ask_popup::-webkit-scrollbar-track, .ask_popup_advanced::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.preferences_popup::-webkit-scrollbar-thumb, .ask_popup::-webkit-scrollbar-thumb, .ask_popup_advanced::-webkit-scrollbar-thumb {
    background: $accent_color1;
}

/* Handle on hover */
.preferences_popup::-webkit-scrollbar-thumb:hover, .ask_popup::-webkit-scrollbar-thumb:hover,  .ask_popup_advanced::-webkit-scrollbar-thumb:hover{
    background: $accent_color2;
}

.advertisement_box {
    &.jpg_a{
        text-align: center;
        img{
            max-width: 70%;
            height: auto;
            @media only screen and (max-width: 991px) {
                max-width: 100%;
            }
        }
        @media only screen and (max-width: 991px) {
            .image{
                padding-bottom: 47% !important;
            }
        }
    }
}

.preferenceshome{
    .chosen-container-single {
        .chosen-search {
            input[type="text"]{
                border:1px solid #d6d6d6;
            }
        }
    }
    .chosen-container {
        .chosen-drop{
            border:1px solid #d6d6d6;
            border-top: none;
        }
    }
    .halff{
        margin: 0 -15px;
        > div{
            display: inline-block;
            vertical-align: top;
            padding: 0 15px;
            width: 50%;
        }
    }
    padding-bottom: 20px;
    label{
        padding-bottom: 6px;
    }
    .input{
        height: 40px;
        padding: 0 20px;
        outline: none;
        font-size: 13px;
        color: $font_color1;
        width: 100%;
        border: none;
        font-weight: 600;
        border-radius: 5px;
        border: 1px solid $border_color;
        background-color:rgba(241, 244, 248, 0.3);
    }
    .holder{
        margin-bottom: 0;
        > div{
            margin-bottom: 23px;
            @media only screen and (max-width: 991px) {
                margin-bottom: 13px;
            }
        }
    }
    .popup_holder{
        //padding: 0 7%;

        @media only screen and (max-width: 1700px) {
            padding: 0;
        }
    }

    .popup_top{
        height: 50px;
        background: $theme_color1;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        img{
            cursor: pointer;
            margin-right: -20px;
            margin-top: -20px;
            cursor: pointer;
        }
    }

    .popup_box{
        border: 2px solid #0071c1;
        padding: 33px 35px;
        background: $white;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        @media only screen and (max-width: 1500px) {
            padding: 23px 25px;
        }
        @media only screen and (max-width: 991px) {
            padding: 15px;
        }
        .popup_desc{
            color: $font_color1;
            font-size: 13px;
            line-height: 23px;
            //margin-bottom: 40px;
            width: 100%;
            header{
                margin-bottom: 15px;
                h2{
                    font-size: 26px;
                    line-height: 36px;
                }
            }
        }
        .holder{
            margin-bottom: 23px;
            .chosen-container-single {
                .chosen-single{
                    border: 1px solid $border_color;
                }
            }
            .check_list{
                width: 100%;
                > ul{
                    > li{
                        display: inline-block;
                        vertical-align: top;
                        width: auto;
                        margin-right: 2.6%;
                        margin-bottom: 0;
                        padding:0;
                        @media only screen and (max-width: 1700px) {
                            margin-right: 3.2%;
                        }
                        @media only screen and (max-width: 1500px) {
                            margin-right: 3%;
                        }
                        @media only screen and (max-width: 991px) {
                            margin-right: 0;
                            width: 100%;
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                        div.checkbox_holder {
                            .checkbox_image_box{
                                width: 30px;
                                height: 30px;
                                background-color:rgba(241, 244, 248, 0.3);
                                @media only screen and (max-width: 1500px) {
                                    width: 23px;
                                    height: 23px;
                                }
                            }
                        }
                        div.checkbox_desc{
                            width: auto;
                            @media only screen and (max-width: 1500px) {
                                padding-left: 10px;
                            }
                        }
                    }
                }
            }
        }
        .popup_summary{
            font-size: 13px;
            line-height: 23px;
        }
    }
}

.preferences_popup, .ask_popup, .ask_popup_advanced{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255,255,255,0.85);
    z-index: 9999;
    //@include vertical;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 6.5vw 0;
    @media only screen and (max-width: 1700px) {
        padding: 50px 0;
    }
    .popup_holder{
        //padding: 0 7%;

        @media only screen and (max-width: 1700px) {
            padding: 0;
        }
    }

    .popup_top{
        height: 50px;
        background: $theme_color1;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        img{
            cursor: pointer;
            margin-right: -20px;
            margin-top: -20px;
            cursor: pointer;
        }
    }

    .popup_box{
        border: 1px solid $border_color;
        padding: 33px 35px;
        background: $white;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        @media only screen and (max-width: 1500px) {
            padding: 23px 25px;
        }
        @media only screen and (max-width: 991px) {
            padding: 15px;
        }
        .popup_desc{
            color: $font_color1;
            font-size: 13px;
            line-height: 23px;
            //margin-bottom: 40px;
            width: 100%;
            header{
                margin-bottom: 15px;
                h2{
                    font-size: 26px;
                    line-height: 36px;
                }
            }
        }
        .holder{
            margin-bottom: 23px;
            .check_list{
                width: 100%;
                > ul{
                    > li{
                        display: inline-block;
                        vertical-align: top;
                        width: auto;
                        margin-right: 2.6%;
                        margin-bottom: 0;
                        padding:0 0 25px 0;
                        @media only screen and (max-width: 1700px) {
                            margin-right: 3.2%;
                        }
                        @media only screen and (max-width: 1500px) {
                            margin-right: 3%;
                        }
                        @media only screen and (max-width: 991px) {
                            margin-right: 0;
                            width: 100%;
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                        div.checkbox_holder {
                            .checkbox_image_box{
                                width: 30px;
                                height: 30px;
                                @media only screen and (max-width: 1500px) {
                                    width: 23px;
                                    height: 23px;
                                }
                            }
                        }
                        div.checkbox_desc{
                            width: auto;
                            @media only screen and (max-width: 1500px) {
                                padding-left: 10px;
                            }
                        }
                    }
                }
            }
        }
        .popup_summary{
            font-size: 13px;
            line-height: 23px;
        }
    }
}


.pagi_holder{
    border: 1px solid $border_color;
    border-radius: 5px;
    > div{
        display: inline-block;
        vertical-align: top;
        &.pagi_sites{
            width: calc(100% - 175px);
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
            .paggination_box{
                height: 52px;
                margin-bottom: 0;
                width: auto;
                border:none;
                @media only screen and (max-width: 991px) {
                    padding: 0;
                    display: table;
                    width: 100%;
                    > div{
                        display: table-cell;
                        vertical-align: middle;
                    }
                }
                .btn_sites{
                    @include bg_pos;
                    cursor: pointer;
                    width: 50px !important;
                    @media only screen and (max-width: 991px) {

                    }
                    &.prev{
                        background-image: url("./../img/pagi_left_arr.png");
                        border-right: none !important;
                    }
                    &.next{
                        background-image: url("./../img/pagi_right_arr.png");
                        border-left: none !important;
                    }
                }
                > div.numbers {
                    ul {
                        li{
                            width: 27px;
                            height: 27px;
                            line-height: 27px;
                            text-align: center;
                            &.active{
                                border:1px solid #0071c1;
                            }
                            a{
                                font-size: 14px !important;
                                color:$font_color1 !important;
                                font-weight: 600 !important;
                            }
                        }
                    }
                }
            }
            .pioromycalendar2_ajax_box_pagination {

            }
        }
        &.pagi_results{
            padding-top: 15px;
            width: 175px;
            font-size: 14px;
            color: $font_color1;
            padding-right: 20px;
        }
    }
}

.nonactive_btn{
    background: #737373 !important;
    cursor: not-allowed !important;
}

.nonactive_btn h4{
    color: #ffffff !important;
}
.nonactive_btn p{
    color: #ffffff !important;
}

.fav_popup{
    z-index: 3;
    @include fill;
    top: 100%;
    @include anim;
    @include vertical;
    &.show_info{
        top: 0;
    }
    .close_this{
        position: absolute;
        top: 5px;
        right: 10px;
        cursor: pointer;
        display: inline-block;
        font-size: 15px;
    }
    > div{
        padding: 30px 10px 30px 10px;
        color: $white;
        background: $accent_color1;
        width: 100%;
        text-align: center;
        position: relative;
        font-size: 13px;
        line-height: 23px;
    }
}

.map_btn{
    line-height: 32px;
    padding: 0 17px;
    font-size: 13px;
    color: $font_color1;
    background: $white;
    display: inline-block;
    cursor: pointer;
    border-radius: 5px;
    @media only screen and (max-width: 991px) {
        width: 100%;
    }
}
.clear_btn{
    background-image: none !important;
}
a.clear_btn:hover{
    color: #616161;
    background-color: transparent;
}
.hidesubmit{
    display:none !important;
}

.search_results{
    padding-bottom: 20px;
}

.myform2_dropzone_box{
    border: 1px solid #d6d6d6;
    border-radius: 5px;
    margin-bottom: 15px;
}
.search_box{
    padding: 1px;
    //background: $accent_color1;
    background: #023177;
    border-radius: 5px;
    .default_font{
        margin-bottom: 10px;
    }
}

section.contact{
    padding-bottom: 90px;
    @media only screen and (max-width: 1500px) {
        padding-bottom: 50px;
    }
    .contact_header{
        margin-bottom: 65px;
        padding-top: 30px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 30px;
        }
        div.default_font{
            h1{
                color: #003580;
                strong{
                    font-weight: 800;
                }
            }
        }
    }
    .contact_data{
        background: $white;
        text-align: center;
        padding: 34px 15px;
        border-radius: 10px;
        border: 1px solid #e5e9ee;
        -webkit-box-shadow: 0px 0px 30px 0px rgba(0,53,128,0.1);
        -moz-box-shadow: 0px 0px 30px 0px rgba(0,53,128,0.1);
        box-shadow: 0px 0px 30px 0px rgba(0,53,128,0.1);
        position: relative;
        z-index: 2;
        ul{
            li{
                display: inline-block;
                vertical-align: middle;
                text-align: left;
                margin-right: 100px;
                @media only screen and (max-width: 1200px) {
                    margin-right: 50px;
                }
                @media only screen and (max-width: 991px) {
                    margin-right: 0;
                    margin-bottom: 15px;
                    width: 100%;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                &:last-child{
                    margin-right: 0;
                }
                div{
                    padding-left: 33px;
                    background-position: left center;
                    background-repeat: no-repeat;
                    font-size: 13px;
                    line-height: 23px;
                    color: $font_color2;
                    min-height: 26px;
                    @media only screen and (max-width: 991px) {
                        padding-left: 0;
                        padding-top: 33px;
                        background-position: center top;
                        text-align: center;
                    }
                    h2{
                        font-size: 18px;
                        line-height: 25px;
                        color: $font_color1;
                        font-weight: 600;
                        @media only screen and (max-width: 991px) {
                            font-size: 16px;
                            line-height: 23px;
                        }
                        a{
                            color: $font_color1;
                            font-weight: 600;
                        }
                    }
                    p{
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

.contact_map{
    height: 490px;
    width: 100%;
    position: relative;
    margin-top: -56px;
    @media only screen and (max-width: 1500px) {
        height: 390px;
    }
    @media only screen and (max-width: 1200px) {
        height: 300px;
    }
    @media only screen and (max-width: 991px) {
        margin-top: 30px;
    }
    iframe{
        @include fill;
    }
}

.main_contact_form{
    background:$white;
    padding: 40px;
    border-top: 3px solid #0077cc;
    margin-top: -80px;
    position: relative;
    z-index: 3;
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0,53,128,0.1);
    -moz-box-shadow: 0px 0px 30px 0px rgba(0,53,128,0.1);
    box-shadow: 0px 0px 30px 0px rgba(0,53,128,0.1);
    @media only screen and (max-width: 991px) {
        margin-top: 30px;
        padding: 30px 15px;
    }
    header{
        margin-bottom: 40px;
        text-align: center;
        img{
            margin-bottom: 30px;
        }
    }
    .row{
        margin: 0 -10px;
        > div{
            padding: 0 10px;
        }
    }
    .desc{
        padding-top: 15px;
        margin-bottom: 50px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 30px;
        }
    }
    .holder{
        margin-bottom: 10px;
        label{
            margin-bottom: 5px;
        }
        .default_input, .default_textarea{
            border-radius: 0;
        }
        .default_submit{
            border: none;
            text-align: center;
        }
    }
}

.visit{
    p{
        font-weight: 600;
        color: $accent_color1;
        cursor: pointer;
    }
}

.small_gall{
    width: 60%;
    @media only screen and (max-width: 991px) {
        width: 100%;
        margin-bottom: 10px;
    }
    > ul{
        margin: 0 -5px;
        > li{
            padding: 0 5px;
            width: 20%;
            display: inline-block;
            vertical-align: top;
            .small_image{
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                @include bg_pos;
                background-size: cover;
            }
        }
    }
}


.search_box_popup{
    position: fixed;
    z-index: 9999999;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.8);
    @include vertical;
    .popup_content{
        position: relative;
        background: $white;
        padding: 30px 30px 0 30px;
        border-radius: 15px;
        @media only screen and (max-width: 1200px) {
            padding: 20px 20px 0 20px;
        }
        .close_popup1{
            position: absolute;
            top: -27px;
            right: -27px;
            cursor: pointer;
            @media only screen and (max-width: 991px) {
                right: -15px;
            }
        }
        .popup_header{
            margin-bottom: 30px;
            @media only screen and (max-width: 1200px) {
                margin-bottom: 20px;
            }
            h2{
                color:#003580;
                @media only screen and (max-width: 1200px) {
                    font-size: 24px;
                }
                strong{
                    font-weight: 800;
                }
            }
        }
    }
}

.popup_gal{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    grid-gap: 5px;
    grid-auto-rows: minmax(8.4vw, auto);
    grid-auto-flow: dense;
    margin-bottom: 40px;
    @media only screen and (max-width: 1200px) {
        margin-bottom: 20px;
    }
    .gal_item{
        border-radius: 10px;
        @include bg_pos;
        background-size: cover;
        @media only screen and (max-width: 991px) {
            border-radius: 3px;
        }
        .small{
            grid-column-end: span 1;
            grid-row-end: span 1;
        }

        .large{
            grid-column-end: span 2;
            grid-row-end: span 2;
        }
    }
}

.popup_main_desc{
    height: 270px;
    overflow-y: scroll;
    display: flex;
    border-top: 1px solid #dddddd;
    @media only screen and (max-width: 1200px) {
        height: 200px;
    }
    .plus_header, .minus_header{
        color:#003580;
        margin-bottom: 30px;
        @media only screen and (max-width: 1200px) {
            margin-bottom: 20px;
        }
        h3{
            display: inline-block;
            padding-right: 37px;
            background-repeat: no-repeat;
            background-position: right center;
            @media only screen and (max-width: 1200px) {
                font-size: 20px;
            }
        }
        strong{
            font-weight: 800;
        }
    }
    > div{
        width: 50%;
        padding-top: 40px;
        padding-bottom: 40px;
        @media only screen and (max-width: 1200px) {
            padding-top: 20px;
            padding-bottom: 20px;
        }
        @media only screen and (max-width: 991px) {
            .default_font{
                font-size: 11px;
            }
        }
        &.plus{
            border-right: 1px solid #dddddd;
            padding-right: 40px;
            @media only screen and (max-width: 1200px) {
                padding-right: 20px;
            }
            .default_font{
                color: #1a5113;
                ul{
                    list-style: none;
                    padding-left: 0;
                    li{
                        padding-left: 27px;
                        position: relative;
                        @media only screen and (max-width: 991px) {
                            padding-left: 22px;
                        }
                        &:before{
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 9px;
                            color:#30a021;
                            height: 9px;
                            width: 9px;
                            @include bg_pos;
                            background-image: url("./../img/small_plus.png");
                        }
                    }
                }
            }
        }
        &.minus{
            padding-left: 40px;
            @media only screen and (max-width: 1200px) {
                padding-left: 20px;
            }
            .default_font{
                color: #402323;
                ul{
                    list-style: none;
                    padding-left: 0;
                    li{
                        padding-left: 27px;
                        position: relative;
                        @media only screen and (max-width: 991px) {
                            padding-left: 22px;
                        }
                        &:before{
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 9px;
                            color: #c72727;
                            height: 9px;
                            width: 9px;
                            @include bg_pos;
                            background-image: url("./../img/small_minus.png");
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .btn_holder2{
        margin-top: 0px;
    }
}

.show_map_filters{
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    //bottom: 4px;
    z-index: 5;
    text-align: center;
    p{
        //font-size: 15px;
        font-size: 13px;
        font-weight: 600;
        color: $white;
        //padding: 15px 15px;
        padding: 7px 15px;
        background:$accent_color2;
        display: inline-block;
        width:100%;
    }
}

.sub_header{
    h3{
        color:#000000;
        font-size: 20px;
    }
}

.preferences_popup, .ask_popup, .ask_popup_advanced, .preferenceshome{
    .checkbox_image_box{
        background-color:rgba(241, 244, 248, 0.3) !important;
    }
    .input{
        background:rgba(241, 244, 248, 0.3);
    }
    .radio_holder{
        background-color:#fbfcfc !important;
    }
    .chosen-container-single {
        .chosen-single{
            background:rgba(241, 244, 248, 0.3) !important;

        }
        .chosen-drop{
            background-color:#fbfcfc !important;
        }
    }
}

.show_big_map{
    &:hover{
        border: 1px solid $accent_color1;
        background-color: $accent_color1;
        background-image: none;
    }
}
p.underphoto{
    background: #089d2f;
    color: #ffffff;
    display: inline-block;
    padding: 0 15px;
    display: inline-block;
    font-size: 13px;
    line-height: 30px;
    border-radius: 5px;
    margin: 5px 0 0 -5px;
    transform: scale(0.8);
}

.pin_popup{
    .small_offer_box{
        width: 380px;
        > div{
            display: inline-block;
            vertical-align: top;
            &.image{
                width: 200px;
                height: 120px;
                @include bg_pos;
                background-size: cover;
                border-radius: 5px;
            }
            &.desc{
                width: calc(100% - 200px);
                padding-left: 14px;
                padding-top: 5px;
                font-size: 11px;
                line-height: 17px;
                .tags{
                    ul{
                        li{
                            p{
                                font-size: 12px;
                                background: $accent_color3;
                            }
                        }
                    }
                }
                .adres{
                    margin: 7px 0;
                    text-decoration: underline;
                }
                header{
                    a{
                        font-size: 18px;
                        line-height: 22px;
                        display: inline-block;
                        font-weight: 800;
                        color: #003580;
                    }
                }
                .ask_btn{
                    margin-top: 10px;
                    background-image: none;
                    padding: 0 16px;
                    color: $white;
                    background: $accent_color2;
                    line-height: 30px;
                    @include anim;
                    border: 1px solid $accent_color2;
                    &:hover{
                        background: $white;
                        color: $accent_color2;
                    }
                }
            }
        }
    }
}


.gm-style .gm-style-iw-c{
    overflow: visible !important;
    padding: 6px !important;
    button{
        width: 35px !important;
        height: 35px !important;
        background: $white !important;
        top:-17.5px !important;
        right: -17.5px !important;
        opacity: 1 !important;
        box-shadow: 0 2px 7px 1px rgba(0,0,0,0.3);
        border-radius: 5px;
        img{
            width: 70% !important;
            height: auto !important;
            margin: 0 auto !important;
        }
    }
}

a.added {
    background-color: $accent_color1;
    border: $accent_color1;
}

.img_link{
    @include fill;
}

.undermaplay{
    position:absolute;
    width:100%;
    left:0;
    bottom: 0;
    .undermaplay_close{
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
        @include bg_pos;
        background-image: url("./../img/a_close.png");
        background-size: 100% auto;
    }
}

.color_5{
    background-color:#003580 !important;
}
.color_3{
    background-color:#000000 !important;
}
.color_1{
    background-color:#508d31 !important;
}
.color_2{
    background-color:#FF0000 !important;
}

.undermaplay{

    .small_offer_box{
        background: $white;
        //height: 235px;
        height: 175px;
        display: flex;
        width: 100%;
        > div{
            &.image{
                width: 44%;
                height: 100%;
                //height: 0;
                //padding-bottom: 44%;
                background-position: center top !important;
                //background-size: contain !important;
                background-size: cover !important;
                background-repeat: no-repeat !important;
            }
            &.desc{
                padding-top: 10px;
                .ask_btn{
                    margin-top: 5px;
                }
                .tags {
                    ul {
                        li{
                            p{
                                font-size: 10px;
                            }
                        }
                    }
                }
                width: 56%;
                padding-left: 10px;
                padding-right: 10px;
                font-size: 11px;
                line-height: 20px;
                header{
                    a{
                        font-size: 15px;
                        color: #003580 !important;
                    }
                }
                .ask_btn_advanced{
                    font-size:9px;
                    line-height: 30px;
                }
            }
        }
    }

}


.label_5{
    background-color:#003580;
    border-radius:5px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
    padding: 5px;
    color: #ffffff;

}
.label_3{
    background-color:#000000;
    border-radius:5px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
    padding: 5px;
    color: #ffffff;
}
.label_1{
    background-color:#508d31;
    border-radius:5px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
    padding: 5px;
    color: #ffffff;
}
.label_2{
    background-color:#FF0000;
    border-radius:5px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
    padding: 5px;
    color: #ffffff;
}

.mobile_link_btn{
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 30px;
    color: $accent_color2;
    @media only screen and (max-width: 991px) {
        display: block;
    }
}

.main_news, .news_details{
    .section_header{
        padding: 20px 0 100px 0;
    }
    .news_content{
        background: #f4f5f9;
        padding-bottom: 3px;
    }
    .news_category_choice{
        margin-top: -92px;
        border:1px solid #e5e9ee;
        background:$white;
        padding: 32px 0;
        text-align: center;
        -webkit-box-shadow: 0px 0px 12px 6px rgba(241,244,248,1);
        -moz-box-shadow: 0px 0px 12px 6px rgba(241,244,248,1);
        box-shadow: 0px 0px 12px 6px rgba(241,244,248,1);
        margin-bottom: 26px;
        border-radius: 10px;
        @media only screen and (max-width: 991px) {
            padding: 15px;
        }
        p{
            font-weight: 600;
        }
        > ul{
            > li{
                display: inline-block;
                vertical-align: middle;
                font-size: 18px;
                color: $font_color1;
                margin-right: 60px;
                @media only screen and (max-width: 991px) {
                    margin-right: 0;
                    width: 100%;
                }
                &:last-child{
                    margin-right: 0;
                }
                .news_categories{
                    @media only screen and (max-width: 991px) {
                        border: 1px solid #e5e9ee;
                        border-bottom: none;
                        border-radius: 10px;
                        margin-top: 15px;
                    }
                    > ul{
                        > li{
                            display: inline-block;
                            vertical-align: top;
                            margin-right: 20px;
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                                margin-right: 0;

                            }
                            &.toggle_active{
                                p{
                                    border:1px solid #003580;
                                    color:#003580;
                                    font-weight: 800;
                                }
                            }
                            p{
                                cursor: pointer;
                                display: inline-block;
                                border: 1px solid #e5e9ee;
                                color: $font_color1;
                                font-size: 13px;
                                line-height: 45px;
                                padding: 0 31px;
                                border-radius: 10px;
                                @media only screen and (max-width: 991px) {
                                    width: 100%;
                                    border: none;
                                    border-bottom: 1px solid #e5e9ee;
                                    border-radius: 0;
                                }
                                @media only screen and (min-width: 991px) {
                                    &:hover{
                                        border:1px solid #003580;
                                        color:#003580;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .other_news{
        .news_list{
            ul{
                li{
                    width: 100%;
                    article{
                        div.image{
                            padding-bottom: 43%;
                            @media only screen and (max-width: 1200px) {
                                padding-bottom: 55%;
                            }
                        }
                        div.desc{
                            background: #eff1f6;
                            padding: 0 30px 10px 30px;
                            @media only screen and (max-width: 1200px) {
                                padding: 0 20px 14px 20px;
                            }
                            .title{
                                padding-top: 10px;
                                margin-bottom: 7px;
                            }
                        }
                    }
                }
            }
        }
    }
    .news_list{
        ul{
            margin: 0 -9px;
            li{
                padding: 0 9px;
                display: inline-block;
                vertical-align: top;
                width: 33.33%;
                margin-bottom: 23px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                &:hover{
                    a{
                        text-decoration: none;
                        article{
                            transform: scale(1.05);
                            -webkit-box-shadow: 0px 0px 12px 6px rgba(241,244,248,1);
                            -moz-box-shadow: 0px 0px 12px 6px rgba(241,244,248,1);
                            box-shadow: 0px 0px 12px 6px rgba(241,244,248,1);
                        }
                    }
                }
                article{
                    @include anim;
                    overflow: hidden;
                    border-radius: 10px;
                    div.image{
                        position: relative;
                        z-index: 1;
                        @include bg_pos;
                        background-size: cover;
                        width: 100%;
                        height: 0;
                        padding-bottom: 55%;
                    }
                    div.desc{
                        background: $white;
                        padding: 0 40px 24px 40px;
                        @media only screen and (max-width: 1200px) {
                            padding: 0 20px 14px 20px;
                        }
                        div{
                            &.date{
                                position: relative;
                                z-index: 2;
                                margin-top: -32px;
                                border-radius: 10px;
                                width:68px;
                                background: #00337a;
                                height: 70px;
                                @include vertical;
                                > div{
                                    width: 100%;
                                    text-align: center;
                                    font-size: 23px;
                                    line-height: 26px;

                                    color: $white;
                                    span{
                                        font-size: 15px;
                                        line-height: 15px;
                                    }
                                    p{
                                        font-weight: 500;
                                    }
                                }
                            }
                            &.title{
                                padding-top: 20px;
                                margin-bottom: 20px;
                                @media only screen and (max-width: 1200px) {
                                    margin-bottom: 15px;
                                }
                                h3{
                                    color: $font_color1;
                                    font-size: 19px;
                                    line-height: 26px;
                                    font-weight: 700;
                                    @media only screen and (max-width: 1200px) {
                                        font-size: 17px;
                                        line-height: 24px;
                                    }
                                }
                            }
                            &.short_desc{
                                color:#737373;
                                font-size: 13px;
                                line-height: 24px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.news_details{
    padding-top: 30px;
    .section_header{
        padding: 0;
        h3{
            font-weight: 800;
            color: #003580;
        }
    }
    .other_header{
        margin-bottom: 30px;
        color: $font_color1;
        h3{
            font-size: 18px;

        }
    }
    .container{
        > div.row{
            margin: 0 -30px;
            @media only screen and (max-width: 1200px) {
                margin: 0 -15px;
            }
            > div{
                padding: 0 30px;
                @media only screen and (max-width: 1200px) {
                    padding: 0 15px;
                }
            }
        }
    }
    .date_header{
        margin-bottom: 30px;
        > div{
            display: inline-block;
            vertical-align: middle;
            @media only screen and (max-width: 991px) {
                vertical-align: top;
            }
            &.date_holder{
                width: 64px;
                .date{
                    position: relative;
                    z-index: 2;
                    border-radius: 10px;
                    width:100%;
                    background: #00337a;
                    height: 84px;
                    @include vertical;
                    > div{
                        width: 100%;
                        text-align: center;
                        font-size: 21px;
                        line-height: 24px;

                        color: $white;
                       > p{
                            font-weight: 500;
                        }
                        span{
                            font-size: 14px;
                            line-height: 14px;
                            font-weight: 500;
                        }
                        .year{
                            padding-top: 6px;
                            color: #92a2b9;
                            font-size: 11px;
                            line-height: 11px;
                        }
                        p{
                            font-weight: 500;
                        }
                    }
                }
            }
            &.desc{
                width: calc(100% - 68px);
                padding-left: 20px;
                font-size: 14px;
                color: $font_color1;
                h2{
                    color: #003580;
                    font-weight: 800;
                    font-size: 24px;
                    line-height: 34px;
                    margin-bottom: 8px;
                    @media only screen and (max-width: 991px) {
                        font-size: 18px;
                        line-height: 28px;
                    }
                }
                > p{
                    font-weight: 600;
                    a{
                        color: #737373;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .news_details_image{
        width: 100%;
        height: 0;
        padding-bottom: 50%;
        @include bg_pos;
        background-size: cover;
        border-radius: 10px;
        margin-bottom: 30px;
    }

    .news_details_desc{
        margin-bottom: 30px;
    }

    .small_gallery{
        > ul{
            margin:0 -10px;
            > li{
                padding: 0 10px;
                display: inline-block;
                vertical-align: top;
                width: 25%;
                margin-bottom: 20px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                .image{
                    border-radius: 10px;
                    width: 100%;
                    height: 0;
                    padding-bottom: 71%;
                    @include bg_pos;
                    background-size: cover;
                }
            }
        }
    }

    .return_bar{
        line-height: 70px;
        padding: 0 50px;
        -webkit-box-shadow: 0px 0px 12px 6px rgba(241,244,248,1);
        -moz-box-shadow: 0px 0px 12px 6px rgba(241,244,248,1);
        box-shadow: 0px 0px 12px 6px rgba(241,244,248,1);
        border:1px solid #e5e9ee;
        background: $white;
        border-radius: 10px;
        margin-top: 10px;
        margin-bottom: 30px;
        @media only screen and (max-width: 991px) {
            padding: 0 20px;
            line-height: 50px;
        }
        a{
            display: inline-block;
            padding-left: 42px;
            background-repeat: no-repeat;
            background-position: left center;
            background-image: url("./../img/return_arr.png");
            font-size:13px;
            color:#00337a;
            font-weight: 700;
        }
    }
}

.new_input{
    font-size: 13px;
    border-radius: 5px;
    border: 0;
    padding: 10px 10px 10px 20px;
    width: 100%;
    font-weight: 600;
}
.multi_list{
    padding-top: 10px;
    li{
        margin-right: 10px;
        background: rgba(241, 244, 248, 0.3);
        font-size: 13px;
        display: inline-block;
        vertical-align: top;
        padding: 2px 10px;
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        margin-bottom: 10px;
        @media only screen and (max-width: 991px) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 10px;
        }
        &:last-child{
            margin-right: 0;
        }
        .glyphicon{
            top: 3px;
            @include bg_pos;
            background-image: url("./../img/a_close.png");
            background-size: 28px;
            width: 13px;
            height: 13px;
            cursor: pointer;
            &:before{
                content: '';
            }
        }
    }
}

.holder{
    .select_multi_box{
        width: 100% !important;
    }
}

.floating-panel {
    position: absolute;
    top: 1%;
    left: 1%;
    z-index: 5;
    background-color: #fff;
    padding: 5px;
    border: 1px solid #003580;
    text-align: center;
    font-size: 12px;
    color:#003580;
    display: none;
}
